import React from 'react'
import Offlinetable from './offlinetable'

const Index = () => {
    return (
        <div>
            <Offlinetable offersType="Active"/>
            
        </div>
    )
}
export default Index
