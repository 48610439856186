export const toasterDuration = 2000;

export const OffersCollection = () => [
  { id: "1", title: "CashBack" },
  { id: "2", title: "Instant CashBack" },
  { id: "3", title: "Combo" },
  { id: "4", title: "Freebie" },
  { id: "5", title: "Discount" },
  { id: "6", title: "Exchange" },
];



export const BankCollection = () => [
    { id: "1", title: "SBI Bank" },
    { id: "2", title: "ICICI" },
    { id: "3", title: "IDFC" },
    { id: "4", title: "HDFC" },
    { id: "5", title: "HDFC Bank" },
    { id: "6", title: "HDFC Flexipay" },
    { id: "7", title: "Standard Chartered Bank" },
    { id: "8", title: "BOB Bank" },
    { id: "9", title: "YES Bank" },
    { id: "10", title: "Kotak Bank" },
    { id: "11", title: "AU Bank" },
  ];


  export const PaymenttypeCollection = () => [
    { id: "1", title: "CASH" },
    { id: "2", title: "DEBIT CARDS" },
    { id: "3", title: "GOOGLE PAY" },
    { id: "4", title: "PHONEPAY" },
    { id: "5", title: "CREDIT CARD" },
    { id: "6", title: "CREDIT & DEBIT CARD" },
    
  ];  

  export const ValidAtCollection = () => [
    { id: "1", title: "Both" },
    { id: "2", title: "Online" },
    { id: "3", title: "Offline" },
    
  ]; 
  export const ValidOnCollection = () => [
    { id: "1", title: "Emi&Non-Emi" },
    { id: "2", title: "Emi" },
    { id: "3", title: "Non-Emi" },
    
  ]; 

  export const ButtonCollection = () => [
    { id: "1", title: "Emi" },
    { id: "2", title: "View" },
    { id: "3", title: "Tc" },
    { id: "4", title: "EMI & View" },
    { id: "5", title: "Emi&Tc" },
    { id: "6", title: "View&Tc" },
    { id: "7", title: "Emi&View&Tc" },
]; 

  export const PaymentMethodCollection = () => [
    { id: "1", title: "CCA" },
    { id: "2", title: "PPG" },
   ];

   export const ImageIconCollection = () => [
    { id: "1", title: "NoCostEMI" },
    { id: "2", title: "InstantCashback" },
    { id: "3", title: "ZerodownPayment" },
    { id: "4", title: "RewardPoints" },
    { id: "5", title: "Cashback" },
   ];


   export const SpecialOffersCollection = () => [
    { id: "1", title: "Bajaj PMI" },
   
   ];

   export const SpecialSubOffersCollection = () => [
    { id: "1", title: "Bajaj Finance" },
    { id: "2", title: "Tv & Laptop" },
   
   ];

   export const SpecialFinancesCollection = () => [
    { id: "1", title: "Finance" },
    { id: "2", title: "Finance" },
   
   ];



   export const EncodeGetParams = (params: any) => {
    return Object.entries(params).map((key: any) => key.map((k: any) => {
        k = typeof k === 'object' ? JSON.stringify(k) : k;
        return encodeURIComponent(k);
    }).join("=")).join("&");
};
   
   



