import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from '../../pages/dashboard/Dashboard';  
import SideBar from '../Sidebar/Bar';
import Appbar from './appbar';
import OfflineOrders from '../../pages/offlineorders/index';
import Addpolicy from '../../pages/insurancepolicy/addpolicy';
import Managepolicy from '../../pages/insurancepolicy/managepolicy';
import Renewpolicy from '../../pages/insurancepolicy/renewpolicy';
import Sendmail from '../../pages/insurancepolicy/sendmail';
import Policytype from '../../pages/insurancepolicy/Policytype';
import Adduser from '../../pages/insurancepolicy/adduser';
import Documentupload from '../../pages/insurancepolicy/documentupload';
import Documenttype from '../../pages/insurancepolicy/adddocumenttype';
import Managedocument from '../../pages/insurancepolicy/managedocuments';
import Senddocumentmail from '../../pages/insurancepolicy/documentsendmail';
import InsuranceCompanyName from '../../pages/insurancepolicy/insurancecompanyname';


const HomeLayout = () => {
  return (
    <div className='overflow-hidden	'>
     
        <div>
        
        <SideBar> 
        <div className='relative'>
          <div className="app_bar p-3">
             
              <div>
                 <Appbar/>
              </div>  
          </div> 
          <div className="bodycontent pl-9 pr-9 overflow-hidden">
            
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="OfflineOrders" element={<OfflineOrders />} />
            <Route path="PolicyAdd" element={<Addpolicy />} />
            <Route path="PolicyAdd/:pid/edit" element={<Addpolicy />} />
            <Route path="ManagePolicy" element={<Managepolicy />} />
            <Route path="Renewpolicy/:pid" element={<Renewpolicy />} />
            <Route path="Sendmail/:pid" element={<Sendmail />} />
            <Route path="PolicyType/" element={<Policytype />} />
            <Route path="InsuranceCompanyName/" element={<InsuranceCompanyName />} />
            <Route path="Adduser/" element={<Adduser />} />
            <Route path="Adduser/:userid/edit" element={<Adduser />} />
            <Route path="Documentupload/" element={<Documentupload />} />
            <Route path="Documentupload/:userid/edit" element={<Documentupload />} />
            <Route path="Documenttype/" element={<Documenttype />} />
            <Route path="Managedocument/" element={<Managedocument />} />
            <Route path="Documentsendmail/:pid" element={<Senddocumentmail />} />

          </Routes>
          </div>
        </div> 
        </SideBar>
        <div className='absolute right-0  font-bold mr-4 mb-2 text-xs'>Copyright ® 2023 <a  href="/" className='text-orange-600'>poorvika.com</a></div>
        </div>
    </div>
  );
};

export default HomeLayout;
