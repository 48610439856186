import React from "react";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";

type InputProps = {
  name?: any;
  label?: any;
  value?: any;
  error?: any;
  onChange?: any;
  placeholder?: string;
  className?: string;
  InputProps?: any;
  type?: any;
  helperText?: any;
  disabled?: any;
  defaultValue?: any;
  variant?: any;
  required?: any;
  onkeypress?: any;
  autoFocus?: any;
  size?: any;
  onKeyPress?: any;
  inputProps?: any;
};

const Input = ({
  type,
  defaultValue,
  disabled,
  name,
  inputProps,
  variant,
  size,
  required,
  autoFocus,
  onkeypress,
  InputProps,
  helperText,
  label,
  value,
  error = null,
  placeholder,
  className,
  onChange,
  onKeyPress,
  ...other
}: InputProps) => {
  return (
    <Box sx={{ m: 1}}>
    <TextField
      type={type}
      autoFocus={autoFocus}
      size="small"
      variant={variant || "outlined"}
      label={label}
      className={className}
      name={name}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress ? onKeyPress : null}
      onKeyUp={onkeypress}
      InputProps={InputProps}
      inputProps={inputProps}
      {...other}
      disabled={disabled}
      required={required}
      defaultValue={defaultValue}
      placeholder={placeholder}
      {...(error && { error: true, helperText: error })}
    />
    </Box>
  );
};

export default Input;
