import { IErrorResponse } from '../services/interfaces/common.interface'
import { AxiosError } from 'axios'





  export const API_ENDPOINTS = {
    
    //Login API
    LOGIN: '/users/login',

   // Main Page API's ********************************************

   // 1.Dashboard API
   DASHBOARD_DATA:'dashboard/counts',


   // 2.Free Offers API
   FREEOFFERSGET:'/freeOffersDetails/getAllDatas',  
   FREEOFFERSINSERT:'/freeOffersDetails/insert',
   FREEOFFERSUPDATE:'/freeOffersDetails/update',
   FREEOFFERSDELETE:'/freeOffersDetails/delete',
   FREEMULTIDELETE:'/freeOffersDetails/multiDelete',


   // 3.Offers API
   OFFERSGET:'/offersDetails/getAllDatas',
   OFFERSINSERT:'/offersDetails/insert',
   OFFERSUPDATE:'/offersDetails/update',
   OFFERSDELETE:'/offersDetails/delete',
   MULTIDELETE:'/offersDetails/multiDelete',

    // 4.Get findItemCode
    FINDITEMCODE:'itemcodedetails/find',


    // 5.offline orders API
    OFFLINEDETAILS:'/offlineDetails/getAllDatas',
    OFFLINEINSERT:'/offlineDetails/insert',
    OFFLINEUPDATE:'/offlineDetails/update',
    OFFLINEDELETE:'/offlineDetails/delete',
    OFFLINE_MULTIDELETE:'/offlineDetails/multiDelete',

     //Sub Offline Orders

   // 1. Bankorders
   OFFLINEBANK_LIST:'/offlineBank/getAllDatas',
   OFFLINEBANK_INSERT:'/offlineBank/insert',
   OFFLINEBANK_UPDATE:'/offlineBank/update',
   OFFLINEBANK_DELETE:'/offlineBank/delete',

   // 2. OfflineModel
   OFFLINEMODEL_LIST:'/offlineModel/getAllDatas',
   OFFLINEMODEL_INSERT:'/offlineModel/insertMany',
   OFFLINEMODEL_UPDATE:'/offlineModel/update',
   OFFLINEMODEL_DELETE:'/offlineModel/delete',

   
   // 3. OfflineTrade
   OFFLINETRADE_LIST:'/offlineTrade/getAllDatas',
   OFFLINETRADE_INSERT:'/offlineTrade/insertMany',
   OFFLINETRADE_UPDATE:'/offlineTrade/update',
   OFFLINETRADE_DELETE:'/offlineTrade/delete',


   // 4. OfflineJio
   OFFLINE_JIO_LIST:'/offlineJio/getAllDatas',
   OFFLINE_JIO_INSERT:'/offlineJio/insertMany',
   OFFLINE_JIO_UPDATE:'/offlineJio/update',
   OFFLINE_JIO_DELETE:'/offlineJio/delete',


    // 6.offline Permission API
    OFFLINE_PERMISSION:'/offlineDetails/permissionUpdate',


  //End  Main Page API's ********************************************


   

    
    //  Setting *****************************
    
    // 1. Offers Type
    OFFERS_TYPE_LIST:'/selectTypeDetails/getAllDatas',
    OFFERS_TYPE_INSERT:'/selectTypeDetails/insert',
    OFFERS_TYPE_UPDATE:'/selectTypeDetails/update',
    OFFERS_TYPE_DELETE:'/selectTypeDetails/delete',
   

     // 2.SPL Offer API
     SPL_OFFER_LIST:'/specialOffer/getAllDatas',
     SPL_OFFER_INSERT:'/specialOffer/insert',
     SPL_OFFER_UPDATE:'/specialOffer/update',
     SPL_OFFER_DELETE:'/specialOffer/delete',
     SPL_OFFER_LIST_MULTIDELETE:'/specialOffer/multiDelete',


    // 3.SPL Offer Category API
    SPL_CATEGORY_LIST:'/splCategory/getAllDatas',
    SPL_CATEGORY_INSERT:'/splCategory/insert',
    SPL_CATEGORY_UPDATE:'/splCategory/update',
    SPL_CATEGORY_DELETE:'/splCategory/delete',


    // 4.SPL Offer Sub Category API
    SPL_SUB_CATEGORY_LIST:'/splSubCategory/getAllDatas',
    SPL_SUB_CATEGORY_INSERT:'/splSubCategory/insert',
    SPL_SUB_CATEGORY_UPDATE:'/splSubCategory/update',
    SPL_SUB_CATEGORY_DELETE:'/splSubCategory/delete',


     // 5.SPL Offer Finance Category API
    SPL_FINANCE_LIST:'/splFinance/getAllDatas',
    SPL_FINANCE_INSERT:'/splFinance/insert',
    SPL_FINANCE_UPDATE:'/splFinance/update',
    SPL_FINANCE_DELETE:'/splFinance/delete',
   

    // 6.item code API
    ITEMCODEDETAILS:'/itemcodeDetails/getAllDatas',
    ITEMCODEINSERT:'/itemcodeDetails/insert',
    ITEMCODEDELETE:'/itemcodeDetails/delete',
    ITEMCODEUPDATE:'/itemcodeDetails/update',


    // 7.Bank API
    BANKDETAILS:'/bankDetails/getAllDatas',
    INSERTTABLE:'/bankDetails/insert',
    BANKUPDATE:'/bankDetails/update',
    BANKDELETE:'/bankDetails/delete',
   

    // 8.Payment API
    PAYMENTDETAILS:'/paymentDetails/getAllDatas',
    PAYMENTINSERT:'/paymentDetails/insert',
    PAYMENTUPDATE:'/paymentDetails/update',
    PAYMENTDELETE:'/paymentDetails/delete',

    
    

    // Select collection *************************************

    PAYMENT_COLLECTION:'common/findPaymentDetails',
    BANK_COLLECTION:'common/findBankDetails',
    SPL_ONLY_CATEGORY:'common/findSplcategory',
    SPL_ONLY_SUB_CATEGORY:'common/findSplsubcategory',
    SPL_ONLY_FINANCE:'common/findSplFinance',
    OFLINE_ORDERS_SElECT:'common/findSelectType',
    
    // End Select collection *************************************
    
    


    //UserDetails ApI
    USER_DETAILS_LIST:'/users/getAllDatas',
    USER_DETAILS_INSERT:'/users/insert',
    USER_DETAILS_UPDATE:'/users/update',
    USER_DETAILS_PWD_UPDATE:'/users/update_pwd',
    USER_DETAILS_DELETE:'/users/delete',


   //New Offline Offers

   NEW_OFFLINE_ORDERS_LIST:'/newOffers/getAllDatas',  
   NEW_OFFLINE_ORDERS_INSERT:'/newOffers/insert',  
   NEW_OFFLINE_ORDERS_UPDATE:'/newOffers/update',  
   NEW_OFFLINE_ORDERS_DELETE:'/newOffers/delete',  
       
  }
  


  export const STATUS = {
    SUCCESS:'success',
    FAILURE: 'failure'
  }
  
  export const parseAxiosError = (error: AxiosError): IErrorResponse => {
    if (error.isAxiosError && error.response) {
      return { status: STATUS.FAILURE, message: error.message, error }
    }
  
    return { status: STATUS.FAILURE, message: error.message, error }
  }
  
  