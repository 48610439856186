import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import TextField from "@mui/material/TextField";
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from "@mui/material/Button";
// import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import '../../index.css'
import { Autocomplete, InputAdornment } from "@mui/material";
import moment from "moment";
import appContext from "../../context/app.context";
import { EToasterVariants } from "../../services/types/common.type";
import {
  makeStyles,
} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({

  root: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)", // default
        borderRadius: "4px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(230, 113, 17, 0.938)", borderRadius: "4px", // customized
      },
      MuiFormControlLabel: {
        "focused": {
          color: '#000'
        }
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "orange",
        },
      },



    }
  },
}));

const Renewpolicy = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const policyfieldsdata = {
    pid: "",
    policyno: "",
    insurancecompanyname: "",
    premiumamount: "",
    expirydate: "",
    insurancedocument: "",
  };

  const [value, setValue] = React.useState<Date | null>(null) as any;
  const [policydata, setPolicydata] = useState(policyfieldsdata) as any;
  const [policyhistory, setPolicyhistory] = useState([] as any);
  const [policydetails, setPolicydetails] = useState([] as any);
  const [othersdocs, setOthersdocs] = useState([]) as any;
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const [error, setError] = useState([] as any);
  const [company, setCompanyname] = useState([]) as any;
  const goBack = () => {
    navigate("/insurance/ManagePolicy");
  }
  useEffect(() => {
    setPolicydata({
      ...policydata,
      pid: params.pid,
    });
    getcompanyname();
    insuarncehistory(params.pid)
    getpolicydata(params.pid)
  }, [params.pid]);

  const handleInsuranceFile = (e: any) => {
    var files = e.target.files[0];
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const formdata = new FormData();
    formdata.append("file", files);
    axios.post(process.env.REACT_APP_API_URL + 'insurance/gcp_upload', formdata, config).then(res => {

      setPolicydata({
        ...policydata,
        insurancedocument: res.data.imagename,
      })
    }).catch(err => {
      console.log(err);
    });
  }

  const renewpolicydata = (key: any, val: any) => {
    setError("");
    setPolicydata({
      ...policydata,
      [key]: val,
    });
  };
  const getcompanyname = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "insurance/insurance_companyname")
      .then((res) => {
        setCompanyname(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCompanyname = (key: any, val: any) => {
    setError("");
    if (val != null) {
      setPolicydata({
        ...policydata,
        insurancecompanyname: val.label,
      });
    } else {
      setPolicydata({
        ...policydata,
        insurancecompanyname: "",
      });
    }
  }
  const formsubmit = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const loginuser = sessionStorage.getItem('username');
    const date = moment(policydata.expirydate);
    const expiredate = date.format("YYYY-MM-DD");
    const premiumamount = policydata.premiumamount;
    const formdata = {
      ...policydata,
      expdate: expiredate,
      ...policydetails,
      loginusername: loginuser,
      premiumamount: premiumamount
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/insurance_renewpolicy",
        formdata,
        config
      )
      .then(() => {
        showToaster(EToasterVariants.success, "Renew Policy Successful!");
        navigate("/insurance/ManagePolicy");
      })
      .catch((err) => {
        setError(err.response.data.errors);
      });
  };


  const insuarncehistory = (pid: any) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + "insurance/policyhistory/" + pid
      )
      .then((res) => {
        setPolicyhistory(res.data.results)
        setOthersdocs(res.data.results)


      }).catch((err) => {
        console.log(err);
      });
  };

  const getpolicydata = (pid: any) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + "insurance/policydetails/" + pid
      ).then((res) => {
        setPolicydetails(res.data.result[0])
      }).catch((err) => {
        console.log(err);
      });
  };


  return (
    <div className="main_wrapper">
      <PageHeader title="Renew Policy" subTitle="Renew Policy..." />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          formsubmit();
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              size="small"
              value={policydata.policyno}
              onChange={(e) => renewpolicydata("policyno", e.target.value.replace(/[^a-zA-Z0-9-./:]/g, ''))}
              label="Policy No"
              variant="outlined"
            />
            <span className="error-form">
              {error.policyno ? error.policyno : ""}
            </span>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              onChange={handleCompanyname}
              options={company}
              value={
                policydata.insurancecompanyname
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Insurance Company Name"
                  sx={{ borderRadius: "4px" }}
                  classes={{
                    root: classes.root,
                  }}
                />
              )}
            />
            <span className="error-form">
              {error.companyname ? error.companyname : ""}
            </span>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              required
              value={policydata.premiumamount}
              onChange={(e) => renewpolicydata("premiumamount", e.target.value.replace(/[^0-9.]/g, ''))}
              size="small"
              label="Premium Paid With Tax"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
              <DatePicker
                label="ExpiryDate"
                value={value}
                // minDate={new Date()}
                onChange={(newValue) => {
                  setValue(newValue);
                  renewpolicydata("expirydate", newValue);
                }}
                inputFormat="dd/MM/yyyy"
                minDate={new Date()}
                renderInput={(params) => (
                  <TextField size="small" fullWidth {...params} 
                  onKeyPress={(e) => e.preventDefault()}
                  onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              name="upload-insurance"
              fullWidth
              id="outlined-basic"
              onChange={handleInsuranceFile}
              size="small"
              type="file"
              variant="outlined"
              label={"Insurance documents"}
              classes={{
                root: classes.root
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                )
              }}
            />

          </Grid>

        </Grid>


        <Stack direction="row" className="flex justify-between mt-10">
          <Button type="submit" variant="contained" endIcon={<SendIcon />} className="default-btn p-10 w-24 md:w-48 lg:w-48 h-10 font-bold text-black">
            Submit
          </Button>
          <Button
            className="w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn text-black font-bold"
            type="submit"
            variant="contained"
            endIcon={<ArrowBackIcon />}
            onClick={() => goBack()}
          >
            Back
          </Button>
        </Stack>
      </form>
      <div className="createList-table renew-table">
        <table >
          <thead>
            <tr>
              <th>Policy No</th>
              <th>Policy Type</th>
              <th>Registration Number</th>
              <th>Insured Company</th>
              <th>Expiry Date</th>
              <th>Premium Rs With Tax</th>
              <th>Model</th>
              <th>Status</th>
              <th>Action</th>
              <th>Registered Name</th>
              <th>Rc Book Documents</th>
              <th>insurance Documents</th>
              <th>others Documents</th>
              <th>updated Date</th>
              <th>updated By</th>
            </tr>
          </thead>
          <tbody>
            {policyhistory.length > 0 ? policyhistory.map((historydata: any) => (
              <tr>
                <td data-label="Policy No">{historydata.policyno}</td>
                <td data-label="Policy Type">{historydata.policytype}</td>
                <td data-label="Registration Number">{historydata.regno}</td>
                <td data-label="Insured Company">{historydata.companyname}</td>
                <td data-label="Expiry Date">{moment(historydata.expirydate).format("DD-MM-YYYY")}</td>
                <td data-label="Premium Rs With Tax">{historydata.premiumamount}</td>
                <td data-label="Model">{historydata.model}</td>
                <td data-label="Status" className={historydata.status == "Active" ? `green_status` : `red_status`}>{historydata.status}</td>
                <td data-label="Action">{historydata.action}</td>
                <td data-label="Registered Name">{historydata.regname}</td>
                <td data-label="Rc Book Documents">
                  {!!historydata.documents ?
                    <a
                      href={
                        "https://img-dev-insurance.poorvika.com/INSURANCE/" +
                        historydata.documents
                      }
                      target="_blank"
                      download
                    ><DescriptionIcon
                        fontSize="small"
                        color="error"
                      /></a>
                    : ""}
                </td>

                <td data-label="insurance Documents">
                  {!!historydata.insurance_document ?
                    <a
                      href={
                        "https://img-dev-insurance.poorvika.com/INSURANCE/" +
                        historydata.insurance_document
                      }
                      target="_blank"
                      download
                    ><DescriptionIcon
                        fontSize="small"
                        color="error"
                      /></a>
                    : ""}
                </td>


                <td data-label="Other Documents Name">
                  {!!historydata.other_document ?
                    JSON.parse(historydata.other_document).map((otherdocs: any) => {
                      return (<a
                        href={"https://img-dev-insurance.poorvika.com/INSURANCE/" + otherdocs}
                        target="_blank"
                        download
                      ><DescriptionIcon
                          fontSize="small"
                          color="error"
                        /></a>)
                    })
                    : ""}
                </td>

                <td data-label="updated Date">{historydata.updateddate}</td>
                <td data-label="updated By">{historydata.updatedby}</td>
              </tr>
            )) : "No History Found"}
          </tbody>

        </table>
      </div>
    </div>
  );
};
export default Renewpolicy;
