import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputAdornment, RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import appContext from "../../context/app.context";
import { EToasterVariants } from "../../services/types/common.type";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)", // default
        borderRadius: "4px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(230, 113, 17, 0.938)",
        borderRadius: "4px", // customized
      },
      MuiFormControlLabel: {
        focused: {
          color: "#000",
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "orange",
        },
      },
    },
  },
}));
const Addpolicy = () => {
  const classes = useStyles();
  const policyfieldsdata = {
    policytype: "",
    policyno: "",
    insurancecompanyname: "",
    expirydate: "",
    premiumamount: "",
    remarks: "",
    status: "",
    insurancedocument: "",
    othersdocsname: "",
    othersdocument: "",
    rcbookstatus: "",
    rcbookdocument: "",
    registrationno: "",
    make: "",
    model: "",
    personname: "",
    contactno: "",
  };

  const [value, setValue] = React.useState<Date | null>(null) as any;

  const [fromvalue, setFromValue] = React.useState<Date | null>(null) as any;
  const [policy, setPolicytype] = useState([]) as any;
  const [company, setCompanyname] = useState([]) as any;

  const [policydata, setPolicydata] = useState(policyfieldsdata);
  const [optiontext, setOptiontext] = useState(false);
  const [editpolicy, setpolicyedit] = useState([] as any);

  const [error, setError] = useState([] as any);
  const [insurancefile, setInsurancefile] = useState([] as any);
  const [rcbookfile, setRcbookfile] = useState([] as any);
  const [otherdocs, setOtherdocs] = useState<any>([]);
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const [policyStatus, setPolicyStatus] = useState('NewPolicy');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  //test file upoad
  const navigate = useNavigate();

  const params = useParams();
  useEffect(() => {
    getpolicytype();
    getcompanyname();
    editpolicydata();
  }, []);

  const goBack = () => {
    navigate("/insurance/ManagePolicy");
  };
  const getpolicytype = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "insurance/insurance_policytype")
      .then((res) => {
        setPolicytype(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getcompanyname = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "insurance/insurance_companyname")
      .then((res) => {
        setCompanyname(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editpolicydata = () => {
    if (!!params.pid) {
      // alert('asdsa')
      axios
        .get(
          process.env.REACT_APP_API_URL + "insurance/editpolicy/" + params.pid
        )
        .then((res) => {
          {
            res.data.results[0].policytype === "Motor"
              ? setOptiontext(true)
              : setOptiontext(false);
          }
          setpolicyedit(res.data.results[0]);
          if (!!res.data.results[0].otherdocs) {
            var otherdocs = JSON.parse(res.data.results[0].otherdocs);
            setOtherdocs(otherdocs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updatepolicy = (key: any, val: any) => {
    setError("");
    if (editpolicy != "") {
      setpolicyedit({
        ...editpolicy,
        [key]: val,
      });
    } else {
      if (val != null) {
        setPolicydata({
          ...policydata,
          [key]: val,
        });
      } else {
        setPolicydata({
          ...policydata,
          [key]: "",
        });
      }
    }
  };
  const openErrorDialog = () => {
    setErrorDialogOpen(true);
  };
  const closeErrorDialog = () => {
    setErrorDialogOpen(false);
  };
  const handleRcbookFile = (e: any) => {
    var rcbook = e.target.files[0];
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    setError("");
    const formdata = new FormData();
    formdata.append("file", rcbook);
    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/gcp_upload",
        formdata,
        config
      )
      .then((res) => {
        setRcbookfile({
          rcbookfile: res.data.imagename,
        });
      })
      .catch((err) => {
        console.log(err);
        setError({ rcbook: "Uploaded File Only Supported in Image/Pdf" });
      });
  };
  const handleInsuranceFile = (e: any) => {
    setError("");
    var files = e.target.files[0];
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const formdata = new FormData();
    formdata.append("file", files);
    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/gcp_upload",
        formdata,
        config
      )
      .then((res) => {
        setInsurancefile({
          insurancefile: res.data.imagename,
        });
      })
      .catch((err) => {
        setError({ insuredocs: "Uploaded File Only Supported in Image/Pdf" });
      });
  };
  var loginusername1 = sessionStorage.getItem("username")

  const deletegcpFile = (docs: any, pid: any, remarks: any, status: any, loginusername: any, policytype: any, registrationno: any, policyno: any, insurancecompanyname: any, expdate: any, premiumamount: any, model: any, personname: any, type: any) => {
    let toRemove = docs;
    var otherdocsCopy = [...otherdocs];
    var index = otherdocsCopy.indexOf(toRemove);
    if (index > -1) {
      otherdocsCopy.splice(index, 1);
    }
    var data = {
      imgname: docs,
      doc_type: type,
      pid: pid,
      otherdocs: otherdocsCopy,
      remarks: remarks,
      status: status,
      loginusername: loginusername,
      policytype: policytype,
      registrationno: registrationno,
      policyno: policyno,
      insurancecompanyname,
      expdate: expdate,
      premiumamount: premiumamount,
      model: model,
      personname: personname
    }
    axios
      .post(process.env.REACT_APP_API_URL + "insurance/deletegcpfile", data)
      .then((res) => {
        if (res.data.status == 1) {
          setOtherdocs(otherdocsCopy);
        }
      });
  };

  const deletegcpInsureFile = (docs: any, filename: any, pid: any, remarks: any, status: any, loginusername: any, policytype: any, registrationno: any, policyno: any, insurancecompanyname: any, expdate: any, premiumamount: any, model: any, personname: any) => {
    setpolicyedit(({ [docs]: filename, ...editpolicy }) => editpolicy);
    var data = {
      imgname: filename,
      doc_type: docs,
      pid: pid,
      remarks: remarks,
      status: status,
      loginusername: loginusername,
      policytype: policytype,
      registrationno: registrationno,
      policyno: policyno,
      insurancecompanyname,
      expdate: expdate,
      premiumamount: premiumamount,
      model: model,
      personname: personname
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/deletegcpfile", data
      )
      .then((res) => {
        if (res.data.status == 1) {
          alert(" Deleted Successfully");
        }
      });
  };

  const handleOtherFile = (e: any) => {
    setError("");
    var files = e.target.files[0];
    if (otherdocs.length >= 3) {
      openErrorDialog();
      e.target.value = null;
      return;
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const formdata = new FormData();
    formdata.append("file", files);
    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/gcp_upload",
        formdata,
        config
      )
      .then((res) => {
        if (!!res.data.imagename) {
          setOtherdocs([res.data.imagename, ...otherdocs]);
        }
      })
      .catch((err) => {
        setError({ othersdocs: "Uploaded File Only Supported in Image/Pdf" });
      });
  };

  const handlePolicyType = (key: any, val: any) => {
    setError("");
    val != null && val.label === "Motor"
      ? setOptiontext(true)
      : setOptiontext(false);

    if (editpolicy != "") {
      if (val != null) {
        setpolicyedit({
          ...editpolicy,
          policytype: val.label,
        });
      }
    } else {
      if (val != null) {
        setPolicydata({
          ...policydata,
          policytype: val.label,
        });
      } else {
        setPolicydata({
          ...policydata,
          policytype: "",
        });
      }
    }
  };
  const handleCompanyname = (key: any, val: any) => {
    setError("");
    if (editpolicy != "") {
      if (val != null) {
        setpolicyedit({
          ...editpolicy,
          insurancecompanyname: val.label,
        });
      }
    } else {
      if (val != null) {
        setPolicydata({
          ...policydata,
          insurancecompanyname: val.label,
        });
      } else {
        setPolicydata({
          ...policydata,
          insurancecompanyname: "",
        });
      }
    }
  };
  const formsubmit = () => {
    var loginuser = sessionStorage.getItem("username");
    var loginuserid = sessionStorage.getItem("userid");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    if (editpolicy != "") {
      const date = moment(editpolicy.expirydate);
      const expiredate = date.format("YYYY-MM-DD");
      const formdata = {
        ...editpolicy,
        expdate: expiredate,
        loginusername: loginuser,
        loginuserid: loginuserid,
        ...insurancefile,
        // ...othersfile,
        ...rcbookfile,
        otherfiles: JSON.stringify(otherdocs),
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + "insurance/insurance_updatepolicy",
          formdata,
          config
        )
        .then((res) => {
          if (res.data.status == 0) {
            setError(res.data.errors);
          } else {
            navigate("/insurance/ManagePolicy");
            showToaster(EToasterVariants.success, "Policy Updated Successful!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // let insuran;
      const date = moment(policydata.expirydate);
      const expiredate = date.format("YYYY-MM-DD");

      const formdata = {
        ...policydata,
        policy_status: policyStatus,
        expiry_start_date: fromvalue,
        expdate: expiredate,
        loginusername: loginuser,
        loginuserid: loginuserid,
        ...insurancefile,
        // ...othersfile,
        ...rcbookfile,
        otherfiles: JSON.stringify(otherdocs),
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + "insurance/insurance_addpolicy",
          formdata,
          config
        )
        .then((res) => {
          if (res.data.status == 0) {

            setError(res.data.errors);
          } else {
            navigate("/insurance/ManagePolicy");
            showToaster(EToasterVariants.success, "Policy Added Successful!");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <div className="main_wrapper">
      {params.pid ? (
        <PageHeader
          title="Edit Policy"
          subTitle="Edit Policy..."
          icon={
            <PostAddIcon className="pr-2" color="primary" fontSize="large" />
          }
        />
      ) : (
        <PageHeader
          title="Add Policy"
          subTitle="Add Policy..."
          icon={
            <PostAddIcon className="pr-2" color="primary" fontSize="large" />
          }
        />
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          formsubmit();
        }}
      >
          <Grid item xs={12} md={4} lg={4}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <Typography style={{ marginLeft: '10px' }}>
                Policy Status :
              </Typography>
              <RadioGroup
                aria-label="policy-status"
                name="policy-status"
                value={editpolicy.policy_status ? editpolicy.policy_status : policyStatus}
                onChange={(e) => {
                  updatepolicy('policy_status', e.target.value);
                  setPolicyStatus(e.target.value);
                }}
                style={{ marginLeft: '10px' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel value="New" control={<Radio />} label="New" />
                  <FormControlLabel value="ReNewal" control={<Radio />} label="ReNewal" />
                </div>
              </RadioGroup>
            </div>
          </Grid>
        <Grid container spacing={2} className="mt-7">

          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              onChange={handlePolicyType}
              options={policy}
              value={
                editpolicy.policytype
                  ? `${editpolicy.policytype}`
                  : `${policydata.policytype}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Policy Type"
                  sx={{ borderRadius: "4px" }}
                  classes={{
                    root: classes.root,
                  }}
                />
              )}
            />
            {error.policytype && (
              <span className="error-form">
                {error.policytype ? error.policytype : ""}
              </span>
            )}
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              // id="outlined-basic"
              id="validation-outlined-input"
              value={
                editpolicy.policyno
                  ? `${editpolicy.policyno}`
                  : `${policydata.policyno}`
              }
              size="small"
              classes={{
                root: classes.root,
              }}
              onChange={(e) => updatepolicy("policyno", e.target.value.replace(/[^a-zA-Z0-9-./:]/g, ''))}
              label={editpolicy.policyno ? "Edit Policy No" : "Policy No"}
              sx={{
                label: { border: "1px solid #fff", borderRadius: "20px" },
                borderRadius: "4px",
              }}
              variant="outlined"
            />
            <span className="error-form">
              {error.policyno ? error.policyno : ""}
            </span>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              onChange={handleCompanyname}
              options={company}
              value={
                editpolicy.insurancecompanyname
                  ? `${editpolicy.insurancecompanyname}`
                  : `${policydata.insurancecompanyname}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Insurance Company Name"
                  sx={{ borderRadius: "4px" }}
                  classes={{
                    root: classes.root,
                  }}
                />
              )}
            />
            <span className="error-form">
              {error.companyname ? error.companyname : ""}
            </span>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Valid From"
                value={editpolicy.expiry_start_date ? editpolicy.expiry_start_date : fromvalue}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFromValue(newValue);
                  updatepolicy("expiry_start_date", newValue); }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ borderRadius: "4px" }}
                    classes={{
                      root: classes.root,
                    }}
                    onKeyPress={(e) => e.preventDefault()

                    }
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
            <span className="error-form">
              {error.expiry_start_date ? error.expiry_start_date : ""}
            </span>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Valid To"
                value={editpolicy.expirydate ? editpolicy.expirydate : value}
                inputFormat="dd/MM/yyyy"
                minDate={new Date()}
                onChange={(newValue) => {
                  setValue(newValue);
                  updatepolicy("expirydate", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ borderRadius: "4px" }}
                    classes={{
                      root: classes.root,
                    }}
                    onKeyPress={(e) => e.preventDefault()

                    }
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
            <span className="error-form">
              {error.expiry ? error.expiry : ""}
            </span>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              type="text"
              id="outlined-basic"
              value={
                editpolicy.premiumamount
                  ? `${editpolicy.premiumamount}`
                  : `${policydata.premiumamount}`
              }
              onChange={(e) => updatepolicy("premiumamount", e.target.value.replace(/[^0-9.]/g, ''))}
              size="small"
              label="Premium Paid With Tax"
              variant="outlined"
              sx={{ borderRadius: "4px" }}
              classes={{
                root: classes.root,
              }}
            />
            <span className="error-form">
              {error.premiumamount ? error.premiumamount : ""}
            </span>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={
                editpolicy.status
                  ? `${editpolicy.status}`
                  : `${policydata.status}`
              }
              options={["Active", "Sold Out", "Not required to insure"]}
              filterOptions={(options, { inputValue }) => {
                const trimmedInput = inputValue.trim().toLowerCase();
                return options.filter(option => option.toLowerCase().includes(trimmedInput));
              }}
              onChange={(event, value) => updatepolicy("status", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  sx={{ borderRadius: "4px" }}
                  classes={{
                    root: classes.root,
                  }}
                />
              )}
            />
            {error.status && (
              <span className="error-form">
                {error.status ? error.status : ""}
              </span>
            )}
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              value={
                editpolicy.remarks
                  ? `${editpolicy.remarks}`
                  : `${policydata.remarks}`
              }
              size="small"
              onChange={(e) => updatepolicy("remarks", e.target.value)}
              label="Remarks"
              multiline
              variant="outlined"
              sx={{ borderRadius: "4px" }}
              classes={{
                root: classes.root,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              name="upload-insurance"
              fullWidth
              id="outlined-basic"
              onChange={handleInsuranceFile}
              size="small"
              type="file"
              variant="outlined"
              label={"Insurance Documents"}
              // sx={{ input: { borderRadius: "4px" }}
              classes={{
                root: classes.root,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />

            <p className="insu-doc">
              <span className="file_text break-all insurance-file">
                {editpolicy.insuredocs ? `${editpolicy.insuredocs}` : ``}
              </span>
              <span className="error-form">
                {error.insuredocs ? error.insuredocs : ""}
              </span>

              <span className="cursor-pointer insur-icon">
                {editpolicy.insuredocs ? (
                  <div className="insur-icon-inner">
                    <a
                      href={
                        editpolicy.insuredocs
                      }
                      target="_blank"
                      download
                    >
                      <FileCopyIcon style={{ color: "rgb(33, 150, 243)" }} />
                    </a>
                    <h3
                      className="insur-delet"
                      onClick={() =>
                        deletegcpInsureFile("insuredocs", editpolicy.insuredocs, params.pid, editpolicy.remarks, editpolicy.status, loginusername1, editpolicy.policytype,
                          editpolicy.registrationno, editpolicy.policyno, editpolicy.insurancecompanyname, editpolicy.expirydate,
                          editpolicy.premiumamount, editpolicy.model, editpolicy.personname)
                      }
                    >
                      X
                    </h3>
                  </div>
                ) : (
                  ""
                )}
              </span>
            </p>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              name="upload-insurance"
              fullWidth
              id="outlined-basic"
              onChange={handleOtherFile}
              size="small"
              type="file"
              variant="outlined"
              label={"Others Documents"}
              // sx={{ input: { borderRadius: "4px" }}
              classes={{
                root: classes.root,
              }}
              inputProps={{ multiple: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />

            {/* {otherdocs?.length == 0 && ( */}
            <div className="uploaded-files">
              {/* <p>Uploaded Files: </p> */}
              <div className="files-others">
                {otherdocs.map((file: any, index: string) => (
                  <div className="files-others-inner">
                    <div className="file-wrp">
                      <p>{file}</p>{" "}
                      <a
                        href={file
                        }
                        target="_blank"
                        download
                      >
                        <FileCopyIcon style={{ color: "rgb(33, 150, 243)" }} />
                      </a>
                      <h5 onClick={() => deletegcpFile(file, params.pid, editpolicy.remarks, editpolicy.status, loginusername1, editpolicy.policytype,
                        editpolicy.registrationno, editpolicy.policyno, editpolicy.insurancecompanyname, editpolicy.expirydate,
                        editpolicy.premiumamount, editpolicy.model, editpolicy.personname, "otherdocs")}>X</h5>
                    </div>
                    <div></div>
                  </div>
                  // <div>{file} <a href={"https://img-dev-insurance.poorvika.com/INSURANCE/"+file} target="_blank" download><FileCopyIcon/></a>
                  // <span onClick={() => deletegcpFile(file)}>X</span></div>
                ))}
              </div>
            </div>
            {/* )} */}
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              value={
                editpolicy.othersdocsname
                  ? `${editpolicy.othersdocsname}`
                  : `${policydata.othersdocsname}`
              }
              size="small"
              onChange={(e) => updatepolicy("othersdocsname", e.target.value)}
              label="Other Documents Name"
              multiline
              variant="outlined"
              sx={{ borderRadius: "4px" }}
              classes={{
                root: classes.root,
              }}
            />
          </Grid>

          {optiontext && (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  value={
                    editpolicy.rcbookstatus
                      ? `${editpolicy.rcbookstatus}`
                      : `${policydata.rcbookstatus}`
                  }
                  id="combo-box-demo"
                  size="small"
                  onChange={(event, value) =>
                    updatepolicy("rcbookstatus", value)
                  }
                  options={["YES", "NO"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="RC Book Required"
                      sx={{ borderRadius: "4px" }}
                      classes={{
                        root: classes.root,
                      }}
                    />
                  )}
                />
                <span className="error-form">
                  {error.rcbookstatus ? error.rcbookstatus : ""}
                </span>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  name="upload-photo"
                  fullWidth
                  onChange={handleRcbookFile}
                  size="small"
                  type="file"
                  label="Rc Book"
                  classes={{
                    root: classes.root,
                  }}
                  // sx={{ input: { color: "white" }, "label": {marginLeft: "20%"}, borderRadius: "4px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                />
                <p className="insu-doc">
                  <span className="file_text">
                    {editpolicy.rcbook ? `${editpolicy.rcbook}` : ``}
                  </span>
                  <span className="error-form">
                    {error.rcbook ? error.rcbook : ""}
                  </span>

                  <span className="insur-icon-rc-book">
                    {editpolicy.rcbook ? (
                      <a
                        href={
                          "https://img-dev-insurance.poorvika.com/INSURANCE/" +
                          editpolicy.rcbook
                        }
                        target="_blank"
                        download
                      >
                        <FileCopyIcon style={{ color: "rgb(33, 150, 243)" }} />
                      </a>
                    ) : (
                      ""
                    )}

                    <h3
                      onClick={() =>
                        deletegcpInsureFile("rcbook", editpolicy.rcbook, params.pid, editpolicy.remarks, editpolicy.status, loginusername1, editpolicy.policytype,
                          editpolicy.registrationno, editpolicy.policyno, editpolicy.insurancecompanyname, editpolicy.expirydate,
                          editpolicy.premiumamount, editpolicy.model, editpolicy.personname)
                      }
                    >
                      {editpolicy.rcbook ? (
                        <h3 className="insur-delet">X</h3>
                      ) : (
                        ""
                      )}
                    </h3>
                  </span>
                </p>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  fullWidth

                  id="outlined-basic"
                  value={
                    editpolicy.registrationno
                      ? `${editpolicy.registrationno}`
                      : `${policydata.registrationno}`
                  }
                  onChange={(e) =>
                    updatepolicy("registrationno", e.target.value.replace(/[^a-zA-Z0-9-./: ]/g, ''))
                  }
                  classes={{
                    root: classes.root,
                  }}
                  size="small"
                  label="Registration No"
                  variant="outlined"
                  sx={{ borderRadius: "4px" }}
                />
                <span className="error-form">
                  {error.registrationno ? error.registrationno : ""}
                </span>
              </Grid>
              {/* <div className={`lg:flex sm:flex md:flex xl:flex block`}>
</div> */}
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  value={
                    editpolicy.make
                      ? `${editpolicy.make}`
                      : `${policydata.make}`
                  }
                  onChange={(e) => updatepolicy("make", e.target.value.replace(/[^a-zA-Z0-9-./: ]/g, ''))}
                  size="small"
                  label="Make"
                  variant="outlined"
                  classes={{
                    root: classes.root,
                  }}
                  sx={{ borderRadius: "4px" }}
                />
                <span className="error-form">
                  {error.make ? error.make : ""}
                </span>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  value={
                    editpolicy.model
                      ? `${editpolicy.model}`
                      : `${policydata.model}`
                  }
                  onChange={(e) => updatepolicy("model", e.target.value)}
                  size="small"
                  label="Model"
                  variant="outlined"
                  classes={{
                    root: classes.root,
                  }}
                  sx={{ borderRadius: "4px" }}
                />
                <span className="error-form">
                  {error.model ? error.model : ""}
                </span>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  value={
                    editpolicy.personname
                      ? `${editpolicy.personname}`
                      : `${policydata.personname}`
                  }
                  onChange={(e) => updatepolicy("personname", e.target.value.replace(/[^a-zA-Z0-9-./: ]/g, ''))}
                  size="small"
                  label="Name"
                  variant="outlined"
                  classes={{
                    root: classes.root,
                  }}
                  sx={{ borderRadius: "4px" }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  value={
                    editpolicy.contactno
                      ? `${editpolicy.contactno}`
                      : `${policydata.contactno}`
                  }
                  onChange={(e) =>
                    updatepolicy(
                      "contactno",
                      e.target.value.replace(/[^0-9]/g, "")
                    )
                  }
                  inputProps={{ maxLength: 10 }}
                  size="small"
                  label="Contact No"
                  variant="outlined"
                  classes={{
                    root: classes.root,
                  }}
                  sx={{ borderRadius: "4px" }}
                />
                <span className="error-form">
                  {error.contactno ? error.contactno : ""}
                </span>
              </Grid>
            </>
          )}
        </Grid>

        <div className="text-black font-bold flex justify-between mr-4 mt-5 ">
          <Button
            className="module-border-wrap w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn ml-10 text-black font-bold"
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
          >
            Submit
          </Button>
          {params.pid && (
            <Button
              className="w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn text-black font-bold"
              type="submit"
              variant="contained"
              endIcon={<ArrowBackIcon />}
              onClick={() => goBack()}
            >
              Back
            </Button>
          )}
        </div>
      </form>
      <Dialog open={errorDialogOpen} onClose={closeErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>You can only upload up to 3 images.</DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Addpolicy;
