import PageHeader from "../../components/PageHeader";
import "../../index.css";
import { Link } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { IconButton, Tooltip } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
//import Export from "react-data-table-component"
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import { FaEdit } from "react-icons/fa";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import appContext from "../../context/app.context";
import "./style.scss";
import { EToasterVariants } from "../../services/types/common.type";
import { SortingTrigger } from "./sortingComponent";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    overflowX: "auto",
    scrollbarWidth: "thin",
    msScrollbarBaseColor: "red",
  },
  root: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)", // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(230, 113, 17, 0.938)", // customized
      },
      MuiFormControlLabel: {
        focused: {
          color: "#000",
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "orange",
        },
      },
    },
  },
}));

const Managepolicy = () => {
  const classes = useStyles();
  const [fromdate, setfromdate] = React.useState<Date | null>(null) as any;
  const [todate, settodate] = React.useState<Date | null>(null) as any;
  const [open, setOpen] = React.useState(false);
  const [_policys, setPolicys] = React.useState(false);
  const [tabledata, settabledata] = useState([] as any);
  const [policyrecord, setpolicyrecords] = useState(false);
  const [searchbar, setsearchdata] = useState([] as any);
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const [policy, setPolicytype] = useState([]) as any;
  const navigate = useNavigate();
  const [policylist, setPolicylist] = useState([] as any);
  // const [loading, setLoading] = React.useState(true);
  const [page, setPage] = useState(0);
  const perPage = 10;
  const [datalist, setDataList] = useState(false);
  const [sortDirection, setSortDirection] = useState('');
  const [isToggle, setIsToggle] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  let role = sessionStorage.getItem('staffRole');

  const getpolicytype = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "insurance/insurance_policytype")
      .then((res) => {
        setPolicytype(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const searchpolicy = (key: any, val: any) => {
    var formdata = {
      searchkeyword: val,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "insurance/search_policy", formdata)
      .then((res) => {
        if (res.data.status == 1) {
          setpolicyrecords(false);

          setPolicylist(res.data.policylists);
        } else {
          setpolicyrecords(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const policysearch = (key: any, val: any) => {
    var data;
    if (key === "policytype" && val != null) {
      data = val.label;
    } else {
      data = val;
    }
    setsearchdata({
      ...searchbar,
      [key]: data,
    });
  };

  const deletepolicy = (pid: any) => {
    if (selectedId) {
      axios
        .get(process.env.REACT_APP_API_URL + "insurance/deletepolicy/" + selectedId)
        .then((res) => {
          if (res.data.status == 1) {
            setShow(false)
            getpolicylist("");
            showToaster(EToasterVariants.success, "Manage Policy Deleted Successfully");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formsubmit = () => {
    var from_date = moment(fromdate);
    var to_date = moment(todate);
    var expiredate_from = from_date.format("YYYY-MM-DD");
    var expiredate_to = to_date.format("YYYY-MM-DD");
    if (expiredate_from == "Invalid date" && expiredate_to == "Invalid date") {
      expiredate_from = "";
      expiredate_to = "";
    }

    const formdata = {
      ...searchbar,
      expirydatefrom: expiredate_from,
      expirydateto: expiredate_to,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "insurance/policy_search", formdata)
      .then((res) => {
        if (res.data.status == 1) {
          setpolicyrecords(false);
          setPolicylist(res.data.policylists);
        } else {
          setpolicyrecords(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpen = (pid: any) => {
    settabledata("");
    axios
      .get(process.env.REACT_APP_API_URL + "insurance/policyhistory/" + pid)
      .then((res) => {
        settabledata(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(true);
  };

  const exportpolicyreport = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "insurance/exportpolicy")
      .then((res) => { });
  };
  const handleClickOpen = (id: any) => {
    setSelectedId(id);
    setShow(true);
  };
  const handleClose = () => {
    setOpen(false);
    setShow(false);
  }

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  var policycount = 0;
  useEffect(() => {
    getpolicylist("");
    getpolicytype();
    exportpolicyreport();
  }, []);

  const getpolicylist = (data: any) => {
    setSpinnerVisibility(true)
    setDataList(!datalist)
    axios
      .get(
        process.env.REACT_APP_API_URL + `insurance/insurance_policylist${data === "" ? "" : `?sort=${datalist ? 'asc' : 'desc'}&sort_by=${data}`}`
      )
      .then((res) => {
        if (res.data.status == 1) {
          // setLoading(false);
          setPolicylist(res.data.policylists);
          setSpinnerVisibility(false)
        } else {
          setpolicyrecords(true);
          setSpinnerVisibility(false)
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SortOrder = (data?: any) => {
    setIsToggle(!isToggle);
    isToggle ? setSortDirection('asc' + data) : setSortDirection('desc' + data);
    setDataList(!datalist)
    getpolicylist(data)
  }

  return (
    <div className="main_wrapper">
      <PageHeader
        title="Manage Policy"
        subTitle="Manage Policy..."
        icon={<ManageHistoryIcon />}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formsubmit();
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={policy}
              onChange={(event, value) => policysearch("policytype", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Policy Type"
                  sx={{ borderRadius: "4px" }}
                  classes={{
                    root: classes.root,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={["Active", "Sold Out", "Not required to insure"]}
              onChange={(event, value) => policysearch("status", value)}
              filterOptions={(options, { inputValue }) => {
                const trimmedInput = inputValue.trim().toLowerCase();
                return options.filter(option => option.toLowerCase().includes(trimmedInput));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  sx={{ borderRadius: "4px" }}
                  classes={{
                    root: classes.root,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              className="w-full"
            >
              <DatePicker
                label="Expiry Date From"
                className="w-full"
                value={fromdate}
                onChange={(newValue) => {
                  setfromdate(newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{ width: "100%", borderRadius: "4px" }}
                    classes={{
                      root: classes.root,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
            <LocalizationProvider dateAdapter={AdapterDateFns} fullWidth={true}>
              <DatePicker
                label="Expiry Date To"
                value={todate}
                onChange={(newValue) => {
                  settodate(newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{ width: "100%", borderRadius: "4px" }}
                    classes={{
                      root: classes.root,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}
              className="w-48 h-10 border bg-gray-500 py-10 default-btn ml-10  text-black font-bold right-0 float-right"
            // sx={{ width: "100%" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>

        <div
          className={`lg:flex sm:flex md:flex xl:flex block mt-10 justify-between`}
        >
          <div
            className={`form_input relative w-full md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}
          >
            <TextField
              fullWidth
              onChange={(e) => searchpolicy("searchkeyword", e.target.value)}
              id="outlined-basic"
              size="small"
              // sx={{color:"green"}}
              // sx={{color:"green", boxShadow:"2px 2px 2px rgba(230, 113, 17, 0.938)"}}
              label="Search"
              variant="outlined"
              className="text-black font-bold"
              placeholder="Search"
              // endIcon={<SearchIcon />}
              classes={{
                root: classes.root,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fill="green" />
                  </InputAdornment>
                ),
              }}

            // icon={<ManageSearchIcon />}
            />
          </div>

          <div className="export-wrp">
            <a
              href={
                process.env.REACT_APP_API_URL +
                "documents/files/policydata.xlsx"
              }
              download
              className="text-xs text-green-600 font-bold"
            >
              Export
              <AssignmentReturnedIcon
                style={{ color: "#fff" }}
                fontSize="small"
              />
              {/* </Button> */}
            </a>
          </div>
        </div>
      </form>

      <div className="createList-table">
        {policyrecord === false ? (
          <table className="scroll_hide  w-full">
            <thead>
              <tr>
                <th onClick={() => { SortOrder("pid") }} style={{ cursor: "pointer" }}>S.No {sortDirection === 'ascpid' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("policytype") }} style={{ cursor: "pointer" }}>Policy Type {sortDirection === 'ascpolicytype' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("policyno") }} style={{ cursor: "pointer" }}>Policy No {sortDirection === 'ascpolicyno' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("premiumamount") }} style={{ cursor: "pointer" }}>Premium Rs with Tax{sortDirection === 'ascpremiumamount' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("insurancecompanyname") }} style={{ cursor: "pointer" }}>Insured Company {sortDirection === 'ascinsurancecompanyname' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("expirydate") }} style={{ cursor: "pointer" }}>Valid From {sortDirection === 'ascdexpirystartDay' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("valideto") }} style={{ cursor: "pointer" }}>Valid To  {sortDirection === 'ascexpirydate' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("validedays") }} style={{ cursor: "pointer" }}>Valid Days {sortDirection === 'ascdiffDays' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("policystatus") }} style={{ cursor: "pointer" }}>Policy Status {sortDirection === 'ascpolicystatus' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("status") }} style={{ cursor: "pointer" }}>Status {sortDirection === 'ascstatus' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("personname") }} style={{ cursor: "pointer" }}>Registered Name {sortDirection === 'ascpersonname' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("registrationno") }} style={{ cursor: "pointer" }}>RC Book/Reg No {sortDirection === 'ascregistrationno' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("model") }} style={{ cursor: "pointer" }}>Model {sortDirection === 'ascmodel' ? '▲' : '▼'}</th>
                <th>Insurance Documents</th>
                <th>Other Documents</th>
                <th>Remarks</th>
                <th onClick={() => { SortOrder("updateddate") }} style={{ cursor: "pointer" }}>Updated Date {sortDirection === 'ascupdateddate' ? '▲' : '▼'}</th>
                <th onClick={() => { SortOrder("updatedby") }} style={{ cursor: "pointer" }}>Updated By {sortDirection === 'ascupdatedby' ? '▲' : '▼'}</th>
                {
                  role == "Viewer" || role == "Creator" ? "" : <th>Actions</th>
                }
              </tr>
            </thead>

            <tbody>
              {policylist
                .slice(page * perPage, (page + 1) * perPage)
                .map((policydata: any, index: any) => {
                  var expirydate = moment(policydata.expirydate).format( "DD-MM-YYYY" );
                  var updated_date = moment(policydata.updateddate).format("DD-MM-YYYY");
                  var ExpireStartDate = moment(policydata.expiry_start_date).format("DD-MM-YYYY");
                  const gapInDays = moment(policydata.expirydate).diff(moment(policydata.expiry_start_date), 'days');
                  const currentDate = moment();
                  const expiryMoment = moment(policydata.expirydate);
                  const diffDays = expiryMoment.diff(currentDate, 'days');
                  var current_date = moment().format("YYYY-MM-DD");
                  var days = moment(policydata.expirydate).diff(
                    current_date,
                    "days"
                  );
                  var otherdocument = JSON.parse(policydata.otherdocs);
                  var ex_date = moment(policydata.expirydate).format(
                    "MM-DD-YYYY"
                  );
                  var nowdate = moment(new Date()).format("MM-DD-YYYY");
                  var date1 = new Date(nowdate);
                  var date2 = new Date(ex_date);
                  var timeDiff = Math.abs(date2.getTime() - date1.getTime());           
                  return (
                    <tr style={policydata.index % 2 ? { background: "#fff" } : { background: "#fff" }}>
                      <td data-label="S.No"> {index + 1}</td>
                      <td data-label="Policy Type">{policydata.policytype}</td>
                      <td data-label="Policy No">
                        <Button onClick={() => handleOpen(policydata.pid)} sx={{ color: "#fb6b0efa", fontWeight: "700" }}>
                          {policydata.policyno}
                        </Button>
                      </td>
                      <td data-label="Premium Rs With Tax">{policydata.premiumamount}</td>
                      <td data-label="Insured Company">{policydata.insurancecompanyname}</td>
                      <td data-label="Expiry Date">{ExpireStartDate}</td>
                      <td data-label="Expiry Date">{expirydate}</td>
                      {
                        diffDays>=0?
                          <td data-label="Expiry Days">{diffDays} Days To Expire</td>
                          :
                          <td data-label="Expiry Days" style={{ color: "red" }}> Expired - {diffDays} Days ago</td>
                      }
                       <td data-label="Policy Status">{policydata.policy_status}</td>
                      <td data-label="Status"
                        className={policydata.status == "Active" ? `green_status` : `red_status`
                        }>{policydata.status}</td>
                      <td data-label="Registered Name">{policydata.personname}</td>
                      <td data-label="RC Book/Reg No" className="space-between">
                        <span className="float-left left-0">
                          {!!policydata.rcbook && !policydata.rcbook.endsWith("undefined") ?
                            <Button sx={{ width: "20px!important" }}>
                              <a
                                href={
                                  policydata.rcbook
                                }
                                target="_blank"
                                download
                              >
                                <PictureAsPdfIcon
                                  fontSize="small"
                                  color="error"
                                />
                              </a>
                            </Button>
                            : ""}
                        </span>
                        <span>
                          {policydata.registrationno.length ? "/" : ""}
                        </span>
                        <span> {policydata.registrationno}</span>
                      </td>
                      <td data-label="Model">{policydata.model}</td>
                      <td data-label="Insurance Documents">
                        {!!policydata.insuredocs && !policydata.insuredocs.endsWith("undefined") ?
                          <Button>
                            <a
                              href={
                                policydata.insuredocs
                              }
                              target="_blank"
                              download
                            >
                              <DescriptionIcon
                                fontSize="small"
                                color="error"
                              />
                            </a>
                          </Button>
                          : ""}
                      </td>
                      <td data-label="Other Documents">

                        {otherdocument.length > 0
                          ? otherdocument.map((othersdocs: any) => (
                            <div className="other-doc">
                              <Button>
                                <a
                                  href={othersdocs}
                                  target="_blank"
                                  download
                                >
                                  {/* {policydata.otherdocs}  */}
                                  <DescriptionIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </a>
                              </Button>
                            </div>

                          )) : ""}
                      </td>
                      <td data-label="Remarks">{policydata.remarks}</td>
                      <td data-label="Updated Date">{updated_date}</td>
                      <td data-label="Updated By">{policydata.updatedby}</td>
                      {
                        role == "Viewer" || role == "Creator" ? '' :
                          <td data-label="Actions" className="border_action flex space-x-2">
                            <Tooltip title="Edit" >
                              <Link
                                // className="defalutlink-btn"
                                to={`/insurance/PolicyAdd/${policydata.pid}/edit`}
                              >
                                <FaEdit fontSize="x-large" fill="white" className="flex-shrink-0  text-md font-bold border bg-gradient-to-r
                                 from-blue-400 to-blue-400 hover:to-orange-500 hover:from-orange-600 text-white
                                    transition-all flex items-center justify-center rounded p-1 p-1 mr-1 rounded "/>
                              </Link>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                // className="default-btn"
                                onClick={() => handleClickOpen(policydata.pid)}
                                color="primary"
                                aria-label="upload picture"
                                // component="Link"
                                className="dele-mobile-wrp"
                              >
                                <DeleteIcon fontSize="medium" fill="white" className="flex-shrink-0  text-sm border bg-gradient-to-r from-red-500 to-red-600 hover:to-orange-500 hover:from-orange-600 text-white
                                     transition-all flex items-center justify-center rounded p-1 p-1 mr-1 rounded "/>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Renew Policy">
                              <Link
                                // className="defalutlink-btn"
                                to={`/insurance/Renewpolicy/${policydata.pid}`}
                              >
                                <RestorePageOutlinedIcon
                                  fontSize="medium"
                                  // color="success"
                                  fill="white"
                                  className="flex-shrink-0  text-sm border bg-gradient-to-r from-green-500 to-green-600 hover:to-orange-500 hover:from-orange-600 text-white
                                  transition-all flex items-center justify-center rounded p-1 p-1 mr-1 rounded "
                                />
                              </Link>
                            </Tooltip>
                            <Tooltip title="Mail">
                              <Link
                                // className="defalutlink-btn"
                                to={`/insurance/Sendmail/${policydata.pid}`}
                              >
                                <EmailIcon fontSize="medium" fill="white" className="flex-shrink-0  text-sm border bg-gradient-to-r from-blue-500 to-blue-600 hover:to-orange-500 hover:from-orange-600 text-white
                               transition-all flex items-center justify-center rounded p-1 p-1 mr-1 rounded"/>
                              </Link>
                            </Tooltip>
                          </td>
                      }
                    </tr>
                  );
                })}
            </tbody>

          </table>
        ) : (
          <div className="norecordfound">
            <p>No Record Found!</p>
          </div>
        )}
      </div>
      <Dialog open={show} onClose={handleClose} PaperProps={{ style: { height: 'auto' } }} >
        <DialogTitle>Confirmation Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deletepolicy}>OK</Button>
        </DialogActions>
      </Dialog>
      <tfoot>
        <TablePagination
          component="div"
          count={policylist.length}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          rowsPerPage={perPage}
          rowsPerPageOptions={[]}
          sx={{ marginTop: "5px" }}
          className={"bg-grey"}
        />
      </tfoot>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="dilog-tilte">
            <div>
              <h4>Policy History</h4>
            </div>

            <div
              className="justify-end flex close-btn cursor-pointer"
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="createList-table createList-table-mobi mt-5">
              <table>
                <thead>
                  <tr>
                    <th>Pid</th>
                    <th>Action</th>
                    <th>Updated Date</th>
                    <th>Updated By</th>
                    <th>Status</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {tabledata.length > 0
                    ? tabledata.map((historydata: any) => (
                      <tr>
                        <td data-label="Pid">{historydata.pid}</td>
                        <td data-label="Action">{historydata.action}</td>
                        <td data-label="Updated Date">
                          {moment(historydata.updateddate).format("DD-MM-YYYY")}
                        </td>
                        <td data-label="Updated By">
                          {historydata.updatedby}
                        </td>
                        <td
                          data-label="Status"
                          className={
                            historydata.status == "Active"
                              ? `green_status`
                              : `red_status`
                          }
                        >
                          {historydata.status}
                        </td>
                        <td data-label="Remarks">
                          <span className="historyblock">
                            {historydata.policytype != ""
                              ? "Policy Type:" + historydata.policytype
                              : ""}
                          </span>
                          <span className="historyblock">
                            {historydata.policyno != ""
                              ? "Policyno:" + historydata.policyno
                              : ""}
                          </span>
                          <span className="historyblock">
                            {historydata.premiumamount != ""
                              ? "Premium Rs With Tax:" +
                              historydata.premiumamount
                              : ""}
                          </span>
                          <span className="historyblock">
                            {historydata.companyname != ""
                              ? "Insured Company:" + historydata.companyname
                              : ""}
                          </span>
                          <span className="historyblock">
                            {historydata.expirydate != ""
                              ? "Updated Date:" + moment(historydata.updateddate).format("DD-MM-YYYY")
                              : ""}
                          </span>
                          <span className="historyblock">
                            {historydata.regname != ""
                              ? "Registered Name:" + historydata.regname
                              : ""}
                          </span>
                          <span className="historyblock">
                            {historydata.regno != ""
                              ? "Regnumber:" + historydata.regno
                              : ""}
                          </span>
                          {
                            historydata.insurance_document.includes('https://img-dev-insurance.poorvika.com/INSURANCE/null') ||
                              historydata.insurance_document.includes('https://img-stage-insr.poorvika.com/INSURANCE/null') ||
                              historydata.insurance_document.includes('https://img-insr.poorvika.com/INSURANCE/null') ||
                              historydata.insurance_document.includes('https://img-dev-insurance.poorvika.com/INSURANCE/undefined') ||
                              historydata.insurance_document.includes('https://img-stage-insr.poorvika.com/INSURANCE/undefined') ||
                              historydata.insurance_document.includes('https://img-insr.poorvika.com/INSURANCE/undefined') ||
                              historydata.insurance_document.includes('https://img-dev-insurance.poorvika.com/INSURANCE/0') ||
                              historydata.insurance_document.includes('https://img-stage-insr.poorvika.com/INSURANCE/0') ||
                              historydata.insurance_document.includes('https://img-insr.poorvika.com/INSURANCE/0') ||
                              historydata.insurance_document === 0||
                              historydata.insurance_document === undefined||
                              historydata.insurance_document === null ?
                              ""
                              :
                              <span className="historyblock">
                                <span>
                                  {historydata.insurance_document != "" &&
                                    "Insurance document:"}
                                </span>
                                <a href={historydata.insurance_document} target="_blank" >
                                  {historydata.insurance_document.includes('https://img-stage-insr.poorvika.com/INSURANCE/') ?
                                    historydata.insurance_document.split('https://img-stage-insr.poorvika.com/INSURANCE/') :
                                    historydata.insurance_document.includes('https://img-dev-insurance.poorvika.com/INSURANCE/') ?
                                      historydata.insurance_document.split('https://img-dev-insurance.poorvika.com/INSURANCE/') :
                                      historydata.insurance_document.includes('https://img-insr.poorvika.com/INSURANCE/') ?
                                        historydata.insurance_document.split('https://img-insr.poorvika.com/INSURANCE') :
                                        historydata.insurance_document
                                  }
                                </a>
                              </span>
                          }
                          {
                            historydata.documents.includes('https://img-dev-insurance.poorvika.com/INSURANCE/null') ||
                              historydata.documents.includes('https://img-stage-insr.poorvika.com/INSURANCE/null') ||
                              historydata.documents.includes('https://img-insr.poorvika.com/INSURANCE/null') ||
                              historydata.documents.includes('https://img-dev-insurance.poorvika.com/INSURANCE/undefined') ||
                              historydata.documents.includes('https://img-stage-insr.poorvika.com/INSURANCE/undefined') ||
                              historydata.documents.includes('https://img-insr.poorvika.com/INSURANCE/undefined') ||
                              historydata.documents.includes('https://img-dev-insurance.poorvika.com/INSURANCE/0') ||
                              historydata.documents.includes('https://img-stage-insr.poorvika.com/INSURANCE/0') ||
                              historydata.documents.includes('https://img-insr.poorvika.com/INSURANCE/0') ||
                              historydata.documents === 0||
                              historydata.documents === undefined||
                              historydata.documents === null ?
                              ""
                              :
                              <span className="historyblock">
                                <span>
                                  {historydata.documents != "" &&
                                    "Rcbook Document:"}
                                </span>
                                <a
                                  href={
                                    historydata.documents
                                  }
                                  target="_blank"
                                >
                                  {historydata.documents.includes('https://img-stage-insr.poorvika.com/INSURANCE/') ?
                                    historydata.documents.split('https://img-stage-insr.poorvika.com/INSURANCE/') :
                                    historydata.documents.includes('https://img-dev-insurance.poorvika.com/INSURANCE/') ?
                                      historydata.documents.split('https://img-dev-insurance.poorvika.com/INSURANCE/') :
                                      historydata.documents.includes('https://img-insr.poorvika.com/INSURANCE/') ?
                                        historydata.documents.split('https://img-insr.poorvika.com/INSURANCE') :
                                        historydata.documents
                                  }
                                </a>
                              </span>
                          }

                          {historydata.other_document.length > 0
                            ? JSON.parse(historydata.other_document).map(
                              (othersdata: any) => (

                                <>
                                  {
                                    othersdata.includes('https://img-stage-insr.poorvika.com/INSURANCE/NULL') ||
                                      othersdata.includes('https://img-dev-insurance.poorvika.com/INSURANCE/NULL') ||
                                      othersdata.includes('https://img-insr.poorvika.com/INSURANCE/NULL') ||
                                      othersdata.includes('https://img-stage-insr.poorvika.com/INSURANCE/undefined') ||
                                      othersdata.includes('https://img-dev-insurance.poorvika.com/INSURANCE/undefined') ||
                                      othersdata.includes('https://img-insr.poorvika.com/INSURANCE/undefined') ||
                                      othersdata.includes('https://img-stage-insr.poorvika.com/INSURANCE/0') ||
                                      othersdata.includes('https://img-dev-insurance.poorvika.com/INSURANCE/0') ||
                                      othersdata.includes('https://img-insr.poorvika.com/INSURANCE/0') ||
                                      othersdata === undefined||
                                      othersdata === 0||
                                      othersdata === null
                                      ?
                                      ""
                                      :
                                      (<span className="historyblock">
                                        <span>
                                          {othersdata != "" && "Others Document:"}
                                        </span>
                                        <a
                                          href={
                                            othersdata
                                          }
                                          target="_blank"
                                        >
                                          {othersdata.includes('https://img-stage-insr.poorvika.com/INSURANCE/') ?
                                            othersdata.split('https://img-stage-insr.poorvika.com/INSURANCE/') :
                                            othersdata.includes('https://img-dev-insurance.poorvika.com/INSURANCE/') ?
                                              othersdata.split('https://img-dev-insurance.poorvika.com/INSURANCE/') :
                                              othersdata.includes('https://img-insr.poorvika.com/INSURANCE/') ?
                                                othersdata.split('https://img-insr.poorvika.com/INSURANCE') :
                                                othersdata
                                          }
                                        </a>
                                      </span>)
                                  }
                                </>
                              )
                            )
                            : ""}
                        </td>
                      </tr>
                    ))
                    :
                    <tr className="norecordfound">
                      <td >
                        <p>No History Found!</p>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Managepolicy;
