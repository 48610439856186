import React, { useContext, useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgress } from '@material-ui/core';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import spinner from "../../asset/loader.gif"
import { makeStyles } from "@mui/styles";
import appContext from "../../context/app.context";
import { EToasterVariants } from "../../services/types/common.type";




const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        overflowX: "auto",
        scrollbarWidth: "thin",
        msScrollbarBaseColor: "red",
    },
    root: {
        color: "orange",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)", // default
            },
            "&.Mui-focused fieldset": {
                border: "1px solid rgba(230, 113, 17, 0.938)", // customized
            },
            MuiFormControlLabel: {
                focused: {
                    color: "#000",
                },
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: "orange",
                },
            },
        },
    },


    textarea: {

        color: '#333',
        '&:focus': {
            outline: 'none',
            border: "1px solid rgba(230, 113, 17, 0.938) !important", // customized
        },
    },
}));



interface MyTextareaProps extends TextareaAutosizeProps {
    className?: string;
}

const Renewpolicy = (props: MyTextareaProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const classes = useStyles();

    const policyfieldsdata = {
        policytype: '',
        subject: '',
        frommailid: '',
        tomailid: '',
        otherdocs: '',
        message: '',
        rcbook: '',
        insuredocs: '',
    }

    const [value, setValue] = React.useState(null);

    const [policydata, setPolicydata] = useState(policyfieldsdata) as any;

    const [otherdocument, setOtherdocs] = useState("") as any;

    const [loader, setLoader] = useState(false)

    const [policy, setPolicy] = useState([]) as any;

    const { showToaster, setSpinnerVisibility } = useContext(appContext);
    const [disabled, setDisabled] = useState(true);




    useEffect(() => {
        if (params.pid) {
            axios.get(process.env.REACT_APP_API_URL+'insurance/editpolicy/' + params.pid).then(res => {
                setPolicy(res.data.results[0]);
                {
                    res.data.results[0].policytype == 'Motor' ? setPolicydata({
                        ...policydata,
                        insuredocs: res.data.results[0].insuredocs,
                        otherdocs: res.data.results[0].otherdocs,
                        rcbook: res.data.results[0].rcbook,
                        policytype: res.data.results[0].policytype,
                    }) : setPolicydata({
                        ...policydata,
                        insuredocs: res.data.results[0].insuredocs,
                        otherdocs: res.data.results[0].otherdocs,
                        policytype: res.data.results[0].policytype,
                    })

                    setOtherdocs(JSON.parse(res.data.results[0].otherdocs));
                }
            }).catch(err => {
                console.log(err);
            });
        }


    }, [params.pid])

    
    const removeImages = (docs: any, filename: any = null) => {

        //alert(docs)
        const { [docs]: tmp, ...rest } = policydata;
        setPolicydata(rest);

        if (docs == "otherdocs") {
            let document = [...otherdocument]
            document = document.filter((item: any) =>
                filename !== item
            );
            setOtherdocs(document)
        }
    }



    const goBack = () => {
        navigate("/insurance/ManagePolicy");
    }
    const sendmaildata = (key: any, val: any) => {
        setPolicydata({
            ...policydata,
            [key]: val,
        })
    }
    const formsubmit = () => {
        var loginuser=sessionStorage.getItem('username');
        setSpinnerVisibility(true)
        const formdata = {
            ...policydata,
            otherdocument:otherdocument,
            ...policy,
            loginusername:loginuser
            
        }
        axios.post(process.env.REACT_APP_API_URL+'insurance/insurance_sendmail', formdata).then(res => {
            if (res.data.status == 1) {
                <CircularProgress color="inherit" />
               showToaster(EToasterVariants.success, "Email send Successfully!");
                setSpinnerVisibility(false)
                navigate("/insurance/managepolicy");
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <div className="main_wrapper">
            <PageHeader title="Send Mail" subTitle="Send Mail..." />
            <form onSubmit={(e) => {
                e.preventDefault()
                formsubmit()
            }}>
                <div className={`lg:flex sm:flex md:flex xl:flex block md:w-4/5 md:space-x-2`}>

                       <div className={`relative w-full mb-6`}>
                        <TextField disabled={disabled}
                              InputProps={{
                        readOnly: true,
                      }}
                            fullWidth id="outlined-basic" size="small" label="Poorvika <noreply@mail2.poorvika.com>" variant="outlined" />
                    </div>

                    <div className={`relative w-full mx-0 mb-6`}>

                        <TextField classes={{
                            root: classes.root,
                        }} fullWidth id="outlined-basic" required onChange={(e) => sendmaildata("tomailid", e.target.value)} size="small" label="Email To" variant="outlined" placeholder='Enter mail Id' />

                    </div>

                </div>

                <div className={`lg:flex sm:flex md:flex xl:flex block`}>
                    <div className={`relative w-full md:w-4/5 mb-6`}>
                        <TextField classes={{
                            root: classes.root,
                        }} fullWidth required id="outlined-basic" size="small" onChange={(e) => sendmaildata("subject", e.target.value)} label="subject" variant="outlined" />
                    </div>
                </div>
                <div className={`lg:flex sm:flex md:flex xl:flex block`}>

                    <div className={`relative w-full mx-0 md:w-4/5	mb-6`}>
                        <TextareaAutosize
                            className={`${classes.textarea} ${props.className}`}
                            aria-label="empty textarea"
                            onChange={(e) => sendmaildata("message", e.target.value)}
                            placeholder="Message"
                            style={{ width: "100%", border: "1px solid #ccc", height: "200px", padding: "10px" }}
                            maxRows={5}
                        />
                    </div>

                    {/* <div className={`relative w-full mx-0 md:mx-4 sm:mx-4 lg:mx-4 xl:mx-4 md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}>
</div> */}

                </div>

                <div className={` block`}>
                    <div className={`elipse_content`}>

                        {/* {policydata.insuredocs ? <FilePresentIcon style={{ color: "#0ead0e" }} fontSize="large" /> : ""} {policydata.insuredocs}
                        <span
                            className="absolute bottom-0 0-0 cursor-pointer pl-3 text-red-500"
                            onClick={() => removeImages('insuredocs')}
                        >
                            <sup> {policydata.insuredocs ? "x Insuarnce" : ""} </sup>
                        </span> */}
                        <div>
                <p> {policydata.insuredocs ? <FilePresentIcon style={{ color: "#0ead0e" }} fontSize="small" /> : ""} {policydata.insuredocs}</p>
                </div>

                <div>
                <span
                className="cursor-pointer rcbook-wrp"
                onClick={() => removeImages('insuredocs')}
                >
                <h3 className='rcbook-wrp-inner'> {policydata.insuredocs ? "Insuarnce" : ""} </h3>
                <p className='rcbook-wrp-icon'>X</p>
                </span>
                </div>



                    </div>





                    {otherdocument.length > 0
                        ? otherdocument.map((othersdoc: any) => (

                            <div className={`elipse_content`}>

                            <div>
                            <p>{othersdoc ? <FilePresentIcon style={{ color: "#0ead0e" }} fontSize="small" /> : ""} {othersdoc}</p>
                            </div>

                            <div>
                            <span
                            className="cursor-pointer rcbook-wrp"
                            onClick={() => removeImages('otherdocs', othersdoc)}
                            >
                            <h3 className='rcbook-wrp-inner'> {othersdoc ? "Othersdocs" : ""}</h3>
                            <p className='rcbook-wrp-icon'>X</p>
                            </span>
                            </div>


                            </div>
                        )) : ""}

                </div>


                
                    <div className={`elipse_content`}>

                        <div>
                        <p>{policydata.rcbook ? <FilePresentIcon style={{ color: "#0ead0e" }} fontSize="small" /> : ""} {policydata.rcbook}</p>
                        </div>
                        <div>
                        <span
                        className="cursor-pointer rcbook-wrp"
                        onClick={() => removeImages('rcbook')}
                        >
                        <h3 className='rcbook-wrp-inner'> {policydata.rcbook ? "Rcbook" : ""} </h3>
                        <p className='rcbook-wrp-icon'>X</p>
                        </span>
                        </div>

                    </div>
               


                <Stack direction="row" className="flex justify-between md:w-4/5 mt-5 gap-5">
                    <Button type="submit" variant="contained" endIcon={<SendIcon />} className="default-btn p-10 w-24 md:w-48 lg:w-48 h-10 font-bold text-black">
                        Send
           </Button>
                   
                    <Button
                        className="w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn text-black font-bold"
                        type="submit"
                        variant="contained"
                        endIcon={<ArrowBackIcon />}
                        onClick={() => goBack()}
                    >
                        Back
          </Button>
                </Stack>
            </form>

        </div>
    );
}
export default Renewpolicy
