import { BaseService } from './htttp'
import { API_ENDPOINTS,parseAxiosError} from '../config/config'
import { IErrorResponse, ISuccessResponse,ItemCodeResponse } from './interfaces/common.interface'
import { AxiosError } from "axios";


export class DashboardService extends BaseService {
 
  public async getDashboardCount() : Promise<ISuccessResponse | IErrorResponse>{
    try {
        const { data } = await this.httpClient.get(API_ENDPOINTS.DASHBOARD_DATA,{
            headers: {
              Authorization: `${sessionStorage.getItem('accessToken')}` 
            },
            
          }        
            );

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


 

}
