import React from "react";
import { makeStyles } from "@mui/styles";
import { Card } from "@mui/material";
import { url } from "inspector";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#fdfdff'
  },
  pageHeader: {
    display: "flex",
    marginTop: "21px",
    marginBottom: "15px",
    alignItems: "center",
    // background: "../asset/bg-2.jpg",
    borderBottom: "2px solid #c5afa640",
    borderLeft: "5px solid #1976d2",
    borderImage: "linear-gradient(#de6a27fa,#000) 0 100%",

    // backgroundColor: "#c5afa640",

    // border-bottom: 2px solid black;
  },
  pageHeader1:{
    display: "flex",
    marginTop: "21px",
    marginBottom: "15px",
    alignItems: "center",
    // background: "../asset/bg-2.jpg",
    borderBottom: "2px solid #c5afa640",
    borderLeft: "5px solid #000",
    borderImage: "linear-gradient(#de6a27fa,#000) 0 100%",
    boxShadow: "0px 2px 18px rgb(0 0 0 / 50%)",
  },
  pageIcon: {
    display: "inline-block",
    padding: "3px",
    color: "#3c44b1",
    // border: "1px solid #ccc",
    marginLeft: "15px",
    // backgroundColor: "#c5afa640",

    "& .MuiSvgIcon-fontSizeLarge": {
      fontSize: "33px!important",
    },
  },
  pageTitle: {
    "& p": {
      fontSize: "18px",
      textTransform: "capitalize",
      // marginLeft: "26px",
      fontWeight: "bold",
      color: "#000",
      // marginLeft: "10px",
      backgroundColor: "#c5afa640",
      padding: "7px 30px 7px 15px",

    },
  },
}));

type PageProps = {
  title: any;
  subTitle?: any;
  icon?: any;
};

export default function PageHeader({ title, subTitle, icon }: PageProps) {
  const classes = useStyles();
  // const { title, subTitle, icon } = PageProps;
  return (
    <div className={classes.root}>
      <div className={classes.pageHeader ? classes.pageHeader : classes.pageHeader1}>
        {/* <div className={classes.pageIcon}>{icon}</div> */}
        <div className={classes.pageTitle}>
          <p>{title}</p>
          {/* <small>{subTitle}</small>   */}
        </div>
      </div>
    </div>
  );
}
