import React, { useContext, useEffect, useLayoutEffect } from "react";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Popover from "@mui/material/Popover";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import axios from "axios";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import User from "../../asset/user.png";
import { useAuth } from "../../common/useKeyCloak";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import appContext from "../../context/app.context";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)", // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(230, 113, 17, 0.938)", // customized
      },
      MuiFormControlLabel: {
        focused: {
          color: "#000",
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "orange",
        },
      },
    },
  },
}));
const Appbar = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const keycloak = useAuth();
  const classes = useStyles();
  var username = sessionStorage.getItem("username");
  var email = sessionStorage.getItem("email");
  var mobileNo = sessionStorage.getItem("mobileNo");
  var userid = sessionStorage.getItem("userid");

  const profilefields = {
    username: username,
    email: email,
    mobilenumber: mobileNo,
  };

  const passwordfields = {
    // oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  };

  // const [oldPassword, setOldPassword] = useState<any>(false);
  const [newPassword, setNewPassword] = useState<any>(false);
  const [confirmPassword, setConfirmPassword] = useState<any>(false);

  const [logout, setlogout] = useState(false);
  const [user, setUser] = useState("");
  const [isSmall, setIsSmall] = useState(false);
  const [password, setPassword] = useState(passwordfields) as any;

  const [error, setError] = useState([] as any);
  const [modelbox, setModel] = useState(false);
  const [modelaccount, setModelaccount] = useState(false);
  const [profile, setProfile] = useState(profilefields) as any;
  const [disabled, setDisabled] = useState(true);

  const { setSpinnerVisibility } = useContext(appContext);

  useLayoutEffect(() => {
    setIsSmall(window.innerWidth <= 768);
    window.onresize = (_e: any) => {
      setIsSmall(window.innerWidth <= 768);
    };
  }, []);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const [modelopen, setModelOpen] = useState(false);
  const [modelloading, setModelLoading] = useState(false);
  const [diaShow, setDiaShow] = useState(false);

  // const handlemodelClose = () => {
  //   setModelOpen(false);
  // };
  const handleClose = () => {
    setAnchorEl(null);
    setModel(false);
    setModelaccount(false);
    window.location.reload();
  };

  // const handleClickOldPassword = () => {
  //   setOldPassword(!oldPassword);
  // };
  const handleClickNewPassword = () => {
    setNewPassword(!newPassword);
  };

  const handleClickConfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem("auth")) {
      navigate("/login");
    }
  }, [logout]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const updatepassword = (key: any, val: any) => {
    setError("");
    setPassword({
      ...password,
      [key]: val,
    });
  };

  const updateprofile = (key: any, val: any) => {
    setError("");
    setProfile({
      ...profile,
      [key]: val,
    });
  };
  const formsubmit = (e: any) => {
    e.preventDefault();
    setModelLoading(true);
    setSpinnerVisibility(true);
    var username = sessionStorage.getItem("username");
    var userid = sessionStorage.getItem("userid");
    var userpassword = sessionStorage.getItem("password");
    const formdata = {
      ...password,
      loginusername: username,
      loginuserid: userid,
      loginuserpassword: userpassword,
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/update_password",
        formdata
      )
      .then((res) => {
        if (res.data.status == 1) {
          sessionStorage.setItem("password", res.data.password);
          sessionStorage.clear();
          setlogout(true);
          setSpinnerVisibility(false);
          setModelOpen(true);
        } else {
          setError(res.data.errors);
          setSpinnerVisibility(false);
          // setModelLoading(false);
        }
      })
      .catch((err) => {
        alert(err.data);
      });
    setModelLoading(false);
  };

  const formsubmitprofile = () => {
    setSpinnerVisibility(true);
    const formdata = {
      ...profile,
      loginuserid: userid,
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/updateprofile_info",
        formdata
      )
      .then((res) => {
        if (res.data.status == 1) {
          sessionStorage.setItem("email", res.data.email);
          sessionStorage.setItem("mobileNo", res.data.mobilenumber);
          sessionStorage.setItem("username", res.data.username);
          setSpinnerVisibility(false);
          setDiaShow(true);
          window.location.reload();
        } else {
          setError(res.data.errors);
          setSpinnerVisibility(false);
        }
      })
      .catch((err) => {
        alert(err.data);
        console.log(err);
      });
  };

  const handleLogOut = async (e: any) => {
    e.preventDefault();
    sessionStorage.clear();
    console.log(keycloak?.isAuthenticated, "keycloak?.isAuthenticated");
    if (keycloak?.isAuthenticated) {
      const isLogout = await keycloak.logout();
      if (isLogout) {
        sessionStorage.clear();
        setlogout(true);
      }
    }
    setlogout(true);
    // window.location.href = '/';
  };

  const toggleDisabled = () => {
    setDisabled(false);
  };
  const primary = "#fff";

  return (
    <div className="flex justify-between items-center md:pr-9 lg:pr-9">
      <div>
        {!isSmall && (
          <h1 className="text-orange-400 font-bold text-xl">INSURANCE</h1>
        )}
      </div>
      <div className="flex items-center">
        <p className="mr-2">{sessionStorage.getItem("user_mail")}</p>
        <div
          className="item space-x-4 flex profile-icon"
          aria-describedby={id}
          onClick={handleClick}
        >
          <div>
            <p className="itemcursor_wrp text-sm space-x-4 cursor-pointer text-orange-600">
              {" "}
              <span className="user-icon-bg">
                <img
                  src={User}
                  className="w-8 h-8 rounded-full border border-orange-300"
                />
              </span>
            </p>
          </div>

          <div>
            <SettingsIcon />
          </div>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          // anchorOrigin={{
          //   vertical: "bottom",
          //   horizontal: "left",
          // }}
          PaperProps={{
            style: {
              marginTop: "30px",
            },
          }}
        >
          <div className="popover_wrp">
            {/* <p className="p-3">{sessionStorage.getItem("user_mail")}</p> */}
            {/* <Divider /> */}
            <p className="p-3 flex gap-3 items-center font-semibold">
              <span>
                {" "}
                <img
                  src={User}
                  className="w-8 h-8 rounded-full border border-orange-300"
                />
              </span>
              <span className="capitalize pointer-events-none text-gray-600">
                {sessionStorage.getItem("username")}
                {/* <AccountCircleOutlinedIcon fontSize="small"  /> */}
              </span>
            </p>
            <p
              onClick={() => setModel(true)}
              className="p-3 flex justify-between cursor-pointer"
            >
              <span>Change Password</span>
              <span>
                <LockOutlinedIcon fontSize="small" fill="#fd5f32;" />
              </span>
            </p>
            <Divider />
            <p
              onClick={() => setModelaccount(true)}
              className="p-3 flex justify-between cursor-pointer"
            >
              <span>Settings</span>

              <span>
                <SettingsOutlinedIcon fontSize="small" fill="#fd5f32;" />
              </span>
            </p>
            <Divider />
            <p
              className="p-3 flex justify-between cursor-pointer"
              onClick={handleLogOut}
            >
              <span>Logout</span>

              <span>
                <PowerSettingsNewIcon fontSize="small" fill="#fd5f32;" />
              </span>
            </p>
            {/* <p className="p-3">
              <GppBadIcon/> Roles
            </p>
            <Divider />
            <p className="p-3">
              <AccountCircleIcon/> User Details
            </p> */}
          </div>
        </Popover>
      </div>

      {modelbox && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="cen-popup-box">
              <div className="dilog-tilte">
                <div>
                  <h4>Create New Password</h4>
                </div>
                <div
                  className="justify-end flex close-btn cursor-pointer"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </div>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <form onSubmit={formsubmit}>
                  <div>
                    {/* <div className={`relative w-full mb-6`}>
                    <TextField fullWidth id="outlined-basic" type={oldPassword ? "text" : "password"} onChange={(e) => updatepassword("oldpassword", e.target.value)} classes={{ root: classes.root }} size="small"
                      inputProps={{
                        maxLength: 8,
                        autoComplete: 'new-password'
                      }}

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ background: "" }}
                              onClick={handleClickOldPassword}
                            >
                              {oldPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="OldPassword" variant="outlined" />
                    <span className="error-form">{error.oldpassword}</span>
                  </div> */}
                    <div className={`relative w-full mb-6`}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        type={newPassword ? "text" : "password"}
                        inputProps={{
                          // maxLength: 8,
                          autoComplete: "new-password",
                        }}
                        classes={{ root: classes.root }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{ background: "" }}
                                onClick={handleClickNewPassword}
                              >
                                {newPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={password.newpassword}
                        onChange={(e) =>
                          updatepassword(
                            "newpassword",
                            e.target.value.replace(/\s/g, "")
                          )
                        }
                        size="small"
                        label="NewPassword"
                        variant="outlined"
                      />
                      <span className="error-form">{error.newpassword}</span>
                    </div>
                    <div className={`relative w-full mb-6`}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        type={confirmPassword ? "text" : "password"}
                        value={password.confirmpassword}
                        onChange={(e) => {
                          updatepassword(
                            "confirmpassword",
                            e.target.value.replace(/\s/g, "")
                          );
                        }}
                        size="small"
                        label="ConfirmPassword"
                        classes={{ root: classes.root }}
                        inputProps={{
                          // maxLength: 8,
                          autoComplete: "new-password",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{ background: "" }}
                                onClick={handleClickConfirmPassword}
                              >
                                {confirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                      <span className="error-form">
                        {error.confirmpassword}
                      </span>
                    </div>
                    <Stack direction="row" spacing={2}>
                      <Button
                        className="module-border-wrap w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn ml-10  text-black font-bold"
                        type="submit"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={() => setModel(true)}
                        disabled={modelloading}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </div>
                </form>
              </Typography>
            </div>
          </Modal>
          <Dialog open={modelopen}>
            <DialogContent
              style={{ color: "orange", padding: 20, fontSize: "20px" }}
            >
              Password Changed Successfully...
            </DialogContent>
            {/* <DialogActions>
                  <Button onClick={handlemodelClose}>Ok</Button>
                </DialogActions> */}
          </Dialog>
        </>
      )}

      {modelaccount && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="cen-popup-box">
              <div className="dilog-tilte">
                <div>
                  <h4>User Setting</h4>
                </div>

                <div
                  className="justify-end flex close-btn cursor-pointer"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </div>
              </div>

              <div className="edit-btn">
                <Button
                  size="small"
                  endIcon={<EditIcon fontSize="small" />}
                  variant="contained"
                  onClick={toggleDisabled}
                >
                  Edit
                </Button>
              </div>

              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formsubmitprofile();
                  }}
                >
                  <div>
                    <div className={`relative w-full mx-0 mb-6`}>
                      <TextField
                        disabled={disabled}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        id="outlined-basic"
                        value={profile.username}
                        classes={{ root: classes.root }}
                        onChange={(e) =>
                          updateprofile("username", e.target.value)
                        }
                        size="small"
                        label="UserName"
                        variant="outlined"
                      />
                      <span className="error-form">{error.username}</span>
                    </div>

                    <div className={`relative w-full mx-0 mb-6`}>
                      <TextField
                        disabled={disabled}
                        fullWidth
                        id="outlined-basic"
                        value={profile.email}
                        classes={{ root: classes.root }}
                        onChange={(e) => updateprofile("email", e.target.value)}
                        size="small"
                        label="Email"
                        variant="outlined"
                      />
                      <span className="error-form">{error.useremail}</span>
                    </div>

                    <div className={`relative w-full mx-0 mb-6`}>
                      <TextField
                        disabled={disabled}
                        fullWidth
                        id="outlined-basic"
                        value={profile.mobilenumber}
                        onChange={(e) =>
                          updateprofile(
                            "mobilenumber",
                            e.target.value.replace(/[^0-9]/g, "")
                          )
                        }
                        classes={{ root: classes.root }}
                        size="small"
                        inputProps={{ maxLength: 10 }}
                        label="MobileNumber"
                        variant="outlined"
                      />
                      <span className="error-form">{error.usermobile}</span>
                    </div>
                  </div>

                  <Stack direction="row" spacing={2}>
                    <Button
                      className="module-border-wrap w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn ml-10  text-black font-bold"
                      type="submit"
                      variant="contained"
                      endIcon={<SendIcon />}
                    >
                      Submit
                    </Button>
                  </Stack>
                </form>

                <Dialog open={diaShow}>
                  <DialogContent
                    style={{ color: "orange", padding: 20, fontSize: "20px" }}
                  >
                    Data Updated Successfully...
                  </DialogContent>
                  {/* <DialogActions>
                  <Button onClick={handlemodelClose}>Ok</Button>
                </DialogActions> */}
                </Dialog>
              </Typography>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Appbar;
