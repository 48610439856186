import axios from 'axios'
// import * as _ from "lodash";
import { store } from '../redux/store'
import { API_ENDPOINTS, parseAxiosError } from '../config/config'
import { LOGIN } from '../redux/actions/UserActionTypes'

import { ILoginCredentials } from './interfaces/login.interface'
import { BaseService } from './htttp'
import { IErrorResponse, ISuccessResponse } from './interfaces/common.interface'

export class LoginService extends BaseService {
  // public async login(credentials: ILoginCredentials): Promise<ISuccessResponse | IErrorResponse> {
  //   try {
  //     const { data } = await this.httpClient.post(API_ENDPOINTS.LOGIN, credentials)
  //       store.dispatch({
  //       type: LOGIN,
  //       payload: data.data
  //     })
  //     return data
  //   } catch (error) {
  //     return parseAxiosError(error as AxiosError)
  //   }
  // }
  // public static async login(items:any) {
  //   const { data } = await this.BaseService.post(API_ENDPOINTS.LOGIN, items)
  //   return data;
  // }

  public static async login(data: any) {
    return axios.post(process.env.REACT_APP_API_URL + 'users/login', data)
  }

  public static async ssoLogin(token: any) {
    return axios.post(process.env.REACT_APP_API_URL + 'users/auth/sso-login', {
      headers: {
        authorization: `Bearer ${token}`,
      }
    })
  }

  public static async addpolicy(data: any) {
    return axios.post(process.env.REACT_APP_API_URL + 'insurance/insurance_addpolicy', data)
  }
}
