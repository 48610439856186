import Button from "./Buttonbtn";
import Input from "./Input";
import Select from "./Selectwrp";

const Controls = {
  Button,
  Input,
  Select,
};

export default Controls;
