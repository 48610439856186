import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export class BaseService {
  httpClient: AxiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL+'insurance-nodeapi'});
  

  constructor() {
   this.httpClient.interceptors.request.use((request: AxiosRequestConfig) => {
     return request
   });
 
  }
}