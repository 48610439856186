import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaUserFriends, FaReadme, FaHome, FaLock, FaMoneyBill, FaUser, FaUserPlus, FaPlus, FaHandHoldingMedical, FaEdit, FaUserCircle, FaUserAlt } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
import { AiFillHeart, AiTwotoneFileExclamation } from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs";
import { useLayoutEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import logo from "../../asset/download.png";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PageHeader from "../PageHeader";
import LogoHeader from "../LogoHeader";
import MobileLogo from "../../asset/download.png"

import {
  AiOutlineFileProtect,
  AiOutlineFileText,
  AiOutlineSolution, AiFillAppstore
} from "react-icons/ai";
import { IoIosCopy } from "react-icons/io";
import { GoShield } from "react-icons/go";
import { log } from "console";

type ChildrenProps = {
  children: any;
};

const routes = [
  {
    path: "/insurance/dashboard",
    name: "Dashboard",
    icon: <AiFillAppstore />,
  },
  {
    path: "/insurance/PolicyAdd",
    name: "Add Policy",
    icon: <FaUserPlus />,
  },
  {
    path: "/insurance/ManagePolicy",
    name: "Manage Policy",
    icon: <GoShield />,
  },
  {
    path: "/insurance/Policytype",
    name: "Policy Type",
    icon: <AiOutlineSolution />,
  },
  {
    path: "/insurance/InsuranceCompanyName",
    name: "Insurance Company Name",
    icon: <FaReadme />,
  },
  {
    path: "/insurance/Adduser",
    name: "Add User",
    icon: <FaUserFriends />,
  },
  {
    path: "/insurance/Documentupload",
    name: "Add Govt Proof",
    icon: <IoIosCopy />,
  },
  {
    path: "/insurance/Managedocument",
    name: "Manage Govt Proof",
    icon: <AiOutlineFileText />,
  },
  {
    path: "/insurance/Documenttype",
    name: "Document Type - Govt",
    icon: <AiOutlineFileProtect />,
  },
  {
    // path: "#",
    // name: "Settings",
    //icon: <BiCog />,
    // exact: true,
    subRoutes: [
      // {
      //   path: "/Dashboard/Bankdetails",
      //   name: "Bank Details",
      //   icon: <FaMoneyBill />,
      // },
      // {
      //   path: "/Dashboard/Paymenttype",
      //   name: "Payment Types",
      //   icon: <FaMoneyBill />,
      // },
      // {
      //   path: "/Dashboard/Itemcodedata",
      //   name: "Itemcode Details",
      //   icon: <FaMoneyBill />,
      // },
      // {
      //   path: "/Dashboard/SplOfferCategory",
      //   name: "Finance Category",
      //   icon: <FaMoneyBill />,
      // },
      // {
      //   path: "/Dashboard/SplSubOfferCategory",
      //   name: "Finance Sub Category",
      //   icon: <FaMoneyBill />,
      // },
      // {
      //   path: "/Dashboard/SPLFinance",
      //   name: "Finance Menu",
      //   icon: <FaMoneyBill />,
      // },
      // {
      //   path: "/Dashboard/Offerstypetable",
      //   name: "Offers Type",
      //   icon: <FaMoneyBill />,
      // },
    ],
  },
];

const Bar = ({ children }: ChildrenProps) => {
  let role = sessionStorage.getItem('staffRole')

  const navigate = useNavigate();
  const [isSmall, setIsSmall] = useState(false)
  useLayoutEffect(() => {
    setIsSmall(window.innerWidth <= 768)
    window.onresize = (_e: any) => {
      setIsSmall(window.innerWidth <= 768)
    }
  }, [])
  if (!sessionStorage.getItem("auth")) {
    navigate("/login");
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "150px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "150px",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "220px" : "50px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section space-x-4" >
            <AnimatePresence>
              {isOpen && (
                <img src={logo} style={{ width: '20%' }} />
                //         <LogoHeader
                //           // variants={showAnimation}
                //           // initial="hidden"
                //           // animate="show"
                //           // exit="hidden"
                //           // className="logo"
                //           title="Insurance"
                // subTitle="Insurance"
                //         />

              )}
            </AnimatePresence>

            <div className="bars">
              {!isSmall ? <FaBars onClick={toggle} /> : <img src={MobileLogo} alt={logo} />}
            </div>
          </div>

          <section className="routes">
            {routes.map((route, index) => {
              if ((role == 'Creator') && (route.path != '/insurance/Adduser')) {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      key={index}
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }
                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                  // activeClassName="active"
                  >
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              } else {
                if (role == 'Admin') {
                  if (route.subRoutes) {
                    return (
                      <SidebarMenu
                        key={index}
                        setIsOpen={setIsOpen}
                        route={route}
                        showAnimation={showAnimation}
                        isOpen={isOpen}
                      />
                    );
                  }
                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      className="link"
                    // activeClassName="active"
                    >
                      <div className="icon">{route.icon}</div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                }
                else {
                  if ((role == 'Viewer') && (route.path != '/insurance/PolicyAdd') && (route.path != '/insurance/Adduser') && (route.path != '/insurance/Policytype')
                    && (route.path != '/insurance/InsuranceCompanyName') && (route.path != '/insurance/Documentupload') && (route.path != '/insurance/Documenttype')

                  ) {
                    if (route.subRoutes) {
                      return (
                        <SidebarMenu
                          key={index}
                          setIsOpen={setIsOpen}
                          route={route}
                          showAnimation={showAnimation}
                          isOpen={isOpen}
                        />
                      );
                    }
                    return (
                      <NavLink
                        to={route.path}
                        key={index}
                        className="link"
                      // activeClassName="active"
                      >
                        <div className="icon">{route.icon}</div>
                        <AnimatePresence>
                          {isOpen && (
                            <motion.div
                              variants={showAnimation}
                              initial="hidden"
                              animate="show"
                              exit="hidden"
                              className="link_text"
                            >
                              {route.name}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </NavLink>
                    );
                  }
                }

              }
            })}
          </section>
        </motion.div>
        {!isSmall ?
          <motion.div
            style={{ width: isOpen ? "85%" : "97%" }}
            className={`bar-container relative`}
          >
            {children}
          </motion.div>
          :
          <motion.div
            style={{ width: isOpen ? "88%" : "93%" }}
            className={`bar-container relative`}
          >
            {children}
          </motion.div>}

        {/* <main>

          </main> */}
      </div>
    </>
  );
};

export default Bar;
