import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputAdornment } from "@mui/material";
import Grid from '@mui/material/Grid';
import appContext from "../../context/app.context";
import { EToasterVariants } from "../../services/types/common.type";


import {
  makeStyles,
} from '@material-ui/core/styles'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)", // default
        borderRadius: "4px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(230, 113, 17, 0.938)", borderRadius: "4px", // customized
      },
      MuiFormControlLabel: {
        "focused": {
          color: '#000'
        }
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "orange",
        },
      },



    }
  },
}));
const Addpolicy = () => {
  const classes = useStyles();
  const policyfieldsdata = {
    name: "",
    policytype: "",
    policyno: "",
    expirydate: "",
    remarks: "",
    status: "",
    othersdocument: "",
  };

  const [value, setValue] = React.useState<Date | null>(null) as any;
  const [policy, setPolicytype] = useState([]) as any;
  const [policydata, setPolicydata] = useState(policyfieldsdata);
  const [editpolicy, setpolicyedit] = useState([] as any);
  const [error, setError] = useState([] as any);
  const [insurancefile, setInsurancefile] = useState([] as any);
  const [rcbookfile, setRcbookfile] = useState([] as any);
  const [otherdocs, setOtherdocs] = useState<any>([]);
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  //test file upoad
  const navigate = useNavigate();

  const params = useParams();
  useEffect(() => {
    getpolicytype();

    editpolicydata();
  }, []);
 const openErrorDialog = () => {
    setErrorDialogOpen(true);
 };
    const closeErrorDialog = () => {
    setErrorDialogOpen(false);
  };
  const goBack = () => {
    navigate("/insurance/ManagePolicy");
  }
  const getpolicytype = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL + "insurance/insurance_documenttype"
      )
      .then((res) => {
        setPolicytype(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editpolicydata = () => {
    if (!!params.userid) {
      axios
        .get(
          process.env.REACT_APP_API_URL + "insurance/editdocument/" +
          params.userid
        )
        .then((res) => {
          setpolicyedit({ name: res.data.results[0].name, policytype: res.data.results[0].document_type, policyno: res.data.results[0].identity_number, expirydate: res.data.results[0].expirydate, remarks: res.data.results[0].remarks, otherdocuments: res.data.results[0].otherdocuments, status: res.data.results[0].status, government_id: res.data.results[0].government_id, updatedby: res.data.results[0].updatedby, updateddate: res.data.results[0].updateddate });
          if (!!res.data.results[0].otherdocuments) {
            var otherdocs = JSON.parse(res.data.results[0].otherdocuments)
            setOtherdocs(otherdocs)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updatepolicy = (key: any, val: any) => {
    setError("");
    if (editpolicy != "" && val != null) {
      setpolicyedit({
        ...editpolicy,
        [key]: val,
      });
    } else {
      if (val != null) {
        setPolicydata({
          ...policydata,
          [key]: val,
        });
      } else {
        setPolicydata({
          ...policydata,
          [key]: "",
        });
      }
    }
  };




  const deletegcpFile = (docs: any, userid: any, type: any, document_type: any, document_id: any, name: any, identity_number: any, expirydate: any, status: any, remarks: any, action: any, updatedby: any, updateddate: any,) => {
    let toRemove = docs;
    var otherdocsCopy = [...otherdocs];
    var index = otherdocsCopy.indexOf(toRemove);
    if (index > -1) {
      otherdocsCopy.splice(index, 1);
    }
    var data = {
      imgname: docs,
      doc_type: type,
      pid: userid,
      otherdocs: otherdocsCopy,
      document_type: document_type,
      document_id: document_id,
      name: name,
      identity_number: identity_number,
      expirydate: expirydate,
      status: status,
      remarks: remarks,
      // otherdocuments: otherdocuments,
      action: action,
      updatedby: updatedby,
      updateddate: updateddate,
    }
    axios.post(process.env.REACT_APP_API_URL + "insurance/deletegcpfile/",data)
    .then((res) => {
      if (res.data.status == 1) {
        var toRemove = docs;
        let otherdocsCopy = [...otherdocs];
        var index = otherdocsCopy.indexOf(toRemove);
        if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          otherdocsCopy.splice(index, 1);
        }
        setOtherdocs(otherdocsCopy)
      }

    })
  }
  const handleOtherFile = (e: any) => {
    setError('');
    var files = e.target.files[0];
      if (otherdocs.length >= 3) {
    openErrorDialog();
    e.target.value = null;
    return;
  }
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const formdata = new FormData();
    formdata.append("file", files);
    axios.post(process.env.REACT_APP_API_URL + "insurance/gcp_upload", formdata, config).then(res => {
      if (!!res.data.imagename) {
        setOtherdocs([res.data.imagename, ...otherdocs]);
      }
    }).catch(err => {

      setError({ othersdocs: "Uploaded File Only Supported in Image/Pdf" });
    });
  }

  const handlePolicyType = (key: any, val: any) => {
    setError("");

    if (editpolicy != "" && val != null) {
        setpolicyedit({
          ...editpolicy,
          policytype: val.label,
        });
    }
    else {
      if (val != null) {
        setPolicydata({
          ...policydata,
          policytype: val.label,
        });
      } else {
        setPolicydata({
          ...policydata,
          policytype: "",
        });
      }
    }
  };

  const formsubmit = () => {
    var loginuser = sessionStorage.getItem('username');
    var loginuserid = sessionStorage.getItem('userid')
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    if (editpolicy != "") {
      const date = moment(editpolicy.expirydate);
      const expiredate = date.format('YYYY-MM-DD');

      const formdata = {
        ...editpolicy,
        expdate: expiredate,
        loginusername: loginuser,
        loginuserid: loginuserid,
        otherfiles: JSON.stringify(otherdocs)
      }
      axios.post(process.env.REACT_APP_API_URL + "insurance/insurance_updatedocument", formdata, config).then(res => {
        if (res.data.status == 0) {
          setError(res.data.errors);
        } else {
          navigate('/insurance/Managedocument')
          showToaster(EToasterVariants.success, "Proof Updated Successful!");
        }

      }).catch(err => {
        console.log(err);
      });
    } else {
      const date = moment(policydata.expirydate);
      const expiredate = date.format('YYYY-MM-DD');


      const formdata = {
        ...policydata,
        expdate: expiredate,
        loginusername: loginuser,
        loginuserid: loginuserid,
        otherfiles: JSON.stringify(otherdocs)
      }
      axios.post(process.env.REACT_APP_API_URL + "insurance/insurance_adddocument", formdata, config).then(res => {
        if (res.data.status == 0) {
          setError(res.data.errors);

        } else {
          navigate('/insurance/Managedocument')
          showToaster(EToasterVariants.success, "Proof Added Successful!");
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  return (
    <div className="main_wrapper">
      {params.pid ? (
        <PageHeader
          title="Edit Govt Proof"
          subTitle="Edit Govt Proof..."
          icon={
            <PostAddIcon className="pr-2" color="primary" fontSize="large" />
          }
        />
      ) : (
        <PageHeader
          title="Add Govt Proof"
          subTitle="Add Govt Proof..."
          icon={
            <PostAddIcon
              className="pr-2"
              color="primary"
              fontSize="large"
            />
          }
        />
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          formsubmit();
        }}
      >
        <Grid container spacing={2} >

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              id="validation-outlined-input"
              value={
                editpolicy.name
                  ? `${editpolicy.name}`
                  : `${policydata.name}`
              }
              size="small"
              classes={{
                root: classes.root
              }}
              onChange={(e) => updatepolicy("name", e.target.value.replace(/[^a-zA-Z0-9-./: ]/g, ''))}
              label="Name"
              sx={{ "label": { border: "1px solid #fff", borderRadius: "20px" }, borderRadius: "4px" }}
              variant="outlined"
            />
            <span className="error-form">
              {error.name ? error.name : ""}
            </span>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              onChange={handlePolicyType}
              options={policy}
              value={
                editpolicy.policytype
                  ? `${editpolicy.policytype}`
                  : `${policydata.policytype}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Document Type" sx={{ borderRadius: "4px" }} classes={{
                  root: classes.root
                }}
                />
              )}
            />
            {error.policytype && <span className="error-form">
              {error.policytype ? error.policytype : ""}
            </span>}
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              type="text"
              fullWidth
              // id="outlined-basic"
              id="validation-outlined-input"
              value={
                editpolicy.policyno
                  ? `${editpolicy.policyno}`
                  : `${policydata.policyno}`
              }
              size="small"
              classes={{
                root: classes.root
              }}
              onChange={(e) => updatepolicy("policyno", e.target.value.replace(/[^a-zA-Z0-9-./: ]/g, ''))}
              label={editpolicy.policyno ? "Edit ID Card No" : "ID Card No"}
              sx={{ "label": { border: "1px solid #fff", borderRadius: "20px" }, borderRadius: "4px" }}
              variant="outlined"
            />
            <span className="error-form">
              {error.policyno ? error.policyno : ""}
            </span>
          </Grid>
          {/* <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              value={
                editpolicy.insurancecompanyname
                  ? `${editpolicy.insurancecompanyname}`
                  : `${policydata.insurancecompanyname}`
              }
              size="small"
              label="Insurance Company Name"
              onChange={(e) =>
                updatepolicy("insurancecompanyname", e.target.value)
              }
              variant="outlined"
              sx={{ borderRadius: "4px"}}
              classes={{
                root: classes.root
              }}

            />
            <span className="error-form">
              {error.companyname ? error.companyname : ""}
            </span>
          </Grid>
         */}
          <Grid item xs={12} md={4} lg={4}>

            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
              <DatePicker
                label="ExpiryDate"
                inputFormat="dd/MM/yyyy"
                minDate={new Date()}
                value={editpolicy.expirydate ? editpolicy.expirydate : value}
                onChange={(newValue) => {
                  setValue(newValue);
                  updatepolicy("expirydate", newValue);
                }}
                renderInput={(params) => (
                  <TextField size="small" fullWidth {...params} sx={{ borderRadius: "4px" }}
                    classes={{
                      root: classes.root
                    }}
                    onKeyPress={(e) => e.preventDefault()}
                    onKeyDown={(e) => e.preventDefault()}
                     />
                )}
              />
            </LocalizationProvider>
            <span className="error-form">
              {error.expiry ? error.expiry : ""}
            </span>
          </Grid>
          {/* <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              type="number"
              id="outlined-basic"
              
              value={
                editpolicy.premiumamount
                  ? `${editpolicy.premiumamount}`
                  : `${policydata.premiumamount}`
              }
              onChange={(e) => updatepolicy("premiumamount", e.target.value)}
              size="small"
              label="Premium paid with tax"
              variant="outlined"
              sx={{ borderRadius: "4px"}}
              classes={{
                root: classes.root
              }}

            />
            <span className="error-form">
              {error.premiumamount ? error.premiumamount : ""}
            </span>
          </Grid> */}
          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              //value={`${editpolicy.status}`}
              value={
                editpolicy.status
                  ? `${editpolicy.status}`
                  : `${policydata.status}`
              }
              options={["Active", "InActive"]}
              filterOptions={(options, { inputValue }) => {
                const trimmedInput = inputValue.trim().toLowerCase();
                return options.filter(option => option.toLowerCase().includes(trimmedInput));
              }}
              onChange={(e, value) => updatepolicy("status", value)}
              renderInput={(params) => <TextField {...params} label="Status" sx={{ borderRadius: "4px" }}
                classes={{
                  root: classes.root
                }} />}
            />
            {error.status && <span className="error-form">
              {error.status ? error.status : ""}
            </span>}
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              value={
                editpolicy.remarks
                  ? `${editpolicy.remarks}`
                  : `${policydata.remarks}`
              }
              size="small"
              onChange={(e) => updatepolicy("remarks", e.target.value)}
              label="Remarks"
              multiline
              variant="outlined"
              sx={{ borderRadius: "4px" }}
              classes={{
                root: classes.root
              }}

            />

          </Grid>


          {/* <Grid item xs={12} md={4} lg={4}>

            <TextField
              name="upload-insurance"
              fullWidth
              id="outlined-basic"
              onChange={handleInsuranceFile}
              size="small"
              type="file"
              variant="outlined"
              label={"Insurance documents"}
              // sx={{ input: { borderRadius: "4px" }}
              classes={{
                root: classes.root
              }}
              InputProps={{
                startAdornment: (

                <InputAdornment position="start">

                </InputAdornment>
                )
              }}
            />
            
           
           <p className="insu-doc"><span className="file_text break-all insurance-file">
              {editpolicy.insuredocs ? `${editpolicy.insuredocs}` : ``}
            </span>
            <span className="error-form">{error.insuredocs?error.insuredocs:''}</span>
           
            <span className="cursor-pointer insur-icon">
                         

{editpolicy.insuredocs ?  
<div className="insur-icon-inner"> 
<a href={"https://img-dev-insurance.poorvika.com/INSURANCE/"+editpolicy.insuredocs} target="_blank" download>
<FileCopyIcon style={{ color: "rgb(33, 150, 243)" }} />
  </a>
  <h3
                      className="insur-delet"
                      onClick={() =>
                        deletegcpInsureFile("insuredocs", editpolicy.insuredocs)
                      }
                    >
                      X
                    </h3>
  
  </div>:""}

                          
                        </span></p>
          </Grid>


           */}
          <Grid item xs={12} md={4} lg={4}>

            <TextField
              name="upload-insurance"
              fullWidth
              id="outlined-basic"
              onChange={handleOtherFile}
              size="small"
              type="file"
              variant="outlined"
              label={"Others Documents"}
              // sx={{ input: { borderRadius: "4px" }}
              classes={{
                root: classes.root
              }}
              inputProps={{ multiple: true }}

              InputProps={{
                startAdornment: (

                  <InputAdornment position="start">

                  </InputAdornment>
                )
              }}
            />



            {/* {otherdocs?.length == 0 && ( */}
            <div className="uploaded-files">
              {/* <p>Uploaded Files: </p> */}
              <div className="files-others">

                {otherdocs.map((file: any, index: string) => (

                  <div className="files-others-inner">
                    <div className="file-wrp">
                      <p>{file}</p>{" "}
                      <a
                        href={

                          file
                        }
                        target="_blank"
                        download
                      >
                        <FileCopyIcon style={{ color: "rgb(33, 150, 243)" }} />
                      </a>
                      <h5 onClick={() => deletegcpFile(file, params.userid, "otherdocs", editpolicy.policytype, editpolicy.document_id, editpolicy.name, editpolicy.policyno, editpolicy.expirydate, editpolicy.status, editpolicy.remarks, editpolicy.action, editpolicy.updatedby, editpolicy.updateddate)}>X</h5>
                    </div>
                    <div>

                    </div>
                  </div>
                  //  <div>{file} <a href={"https://img-dev-insurance.poorvika.com/INSURANCE/"+file} target="_blank" download><FileCopyIcon/></a>
                  //     <span onClick={() => deletegcpFile(file)}>X</span></div>



                ))}

              </div>
            </div>
            {/* )} */}
          </Grid>

          {/* <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              value={
                editpolicy.othersdocsname
                  ? `${editpolicy.othersdocsname}`
                  : `${policydata.othersdocsname}`
              }
              size="small"
              onChange={(e) => updatepolicy("othersdocsname", e.target.value)}
              label="others document name"
              multiline
              variant="outlined"
              sx={{ borderRadius: "4px"}}
              classes={{
                root: classes.root
              }}

            />
          </Grid> */}


        </Grid>

        <div className="text-black font-bold flex justify-between mr-4 mt-5 ">

          <Button
            className="module-border-wrap w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn ml-10  text-black font-bold"
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
          >
            Submit
          </Button>
          {params.pid && <Button
            className="w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn text-black font-bold"
            type="submit"
            variant="contained"
            endIcon={<ArrowBackIcon />}
            onClick={() => goBack()}
          >
            Back
          </Button>}
        </div>
      </form>
        <Dialog open={errorDialogOpen} onClose={closeErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>You can only upload up to 3 images.</DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} color="primary">
            OK
          </Button>
        </DialogActions>
        </Dialog>
    </div>
  );
};
export default Addpolicy;
