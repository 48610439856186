import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ClassNames } from "@emotion/react";

type SelectProps = {
  name?: any;
  label?: any;
  value?: any;
  onChange?: any;
  options?: any;
  placeholder?: any;
};

const Selectwrp = ({
  name,
  label,
  value,
  onChange,
  options,
  placeholder,
}: SelectProps) => {
  return (
    <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      >
        <MenuItem value="">None</MenuItem>
        {options?.map((item: { id: any; title: any }) => (
          <MenuItem key={item.id} value={item.title}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Selectwrp;
