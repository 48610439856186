import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { makeStyles } from "@mui/styles";
import appContext from "../../context/app.context";
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { EToasterVariants } from "../../services/types/common.type";
import { FaEdit } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  root: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(230, 113, 17, 0.938)",
      },
      MuiFormControlLabel: {
        focused: {
          color: "#000",
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "orange",
        },
      },
    },
  },

}));

let role = sessionStorage.getItem('staffRole')

const Addpolicytype = () => {
  const policyfields = {
    username: "",
    staffId: "",
    userpassword: "",
    usermobile: "",
    status: "",
    useremail: "",
    staffRole: ""
  };
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [policyuser, setPolicyuser] = useState(policyfields) as any;

  const [error, setError] = useState([] as any);
  const [page, setPage] = useState(0);
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const perPage = 20;
  const [tabledata, settabledata] = useState([] as any);
  // const [sortedData, setSortedData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const params = useParams();
  const [staff, setStaffId] = useState('');
  const [showpw, setShowpw] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };


  const updateuserdata = (key: any, val: any) => {
    setError('')
    setPolicyuser({
      ...policyuser,
      [key]: val
    })

  }

  const getuserlist = () => {
    setSpinnerVisibility(true)
    axios
      .get(
        process.env.REACT_APP_API_URL + "insurance/insurance_stafflist"
      )
      .then((res) => {
        if (res.data.status == 1) {
          settabledata(res.data.stafflist);
          // setSortedData(res.data.stafflist);
          setSpinnerVisibility(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickOpen = (id: any) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const passShow = () => {
    setShowpw(true)
  }
  const editUsertype = (staffId: any) => {
    setStaffId(staffId)
    axios.get(
      process.env.REACT_APP_API_URL + "insurance/editUser/" + staffId
    )
      .then((res) => {
        if (res.data.status == 1) {
          setPolicyuser({
            ...policyuser,
            "status": res.data.results[0].accstatus,
            "usermobile": res.data.results[0].staffMobile,
            "userpassword": res.data.results[0].staffPass,
            "useremail": res.data.results[0].staffEmail,
            "username": res.data.results[0].staffUser,
            "staffRole": res.data.results[0].staffRole,
            "staffId": res.data.results[0].staffId
          })
          // setPolicyTypeField(res.data.results[0]);
        }
      }).catch((err) => {
        console.log(err);
      });
  };

  const deleteuser = (userid: any) => {
    if (selectedId) {
      axios
        .get(
          process.env.REACT_APP_API_URL + "insurance/deleteuser/" + selectedId
        )
        .then((res) => {
          if (res.data.status == 1) {
            getuserlist();
            setOpen(false);
            showToaster(EToasterVariants.success, "Add User Deleted Successfully!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formsubmit = () => {
    var emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    var valid = emailRegex.test(policyuser.useremail);

    if (policyuser.useremail != '') {
      if (valid == false) {
        setError({ useremail: "Email Id is Not Valid" })
        return false;
      }
    }
    const formdata = {
      ...policyuser
    }
    if (!!formdata.staffId) {
      axios.post(process.env.REACT_APP_API_URL + 'insurance/updateUser/' + staff, formdata).then(res => {
        if (res.data.status == 1) {
          window.location.reload();
        } else {
          setError(res.data.errors)
        }
      }).catch(err => {
        console.log(err);
      });
    }
    else {
      axios.post(process.env.REACT_APP_API_URL + 'insurance/insurance_adduserdata', formdata).then(res => {
        if (res.data.status == 1) { window.location.reload(); } else {
          setError(res.data.errors)
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  useEffect(() => {
    getuserlist();
  }, []);

  return (
    <div className="main_wrapper">
      <PageHeader title="Add User" subTitle="Add User..." />
      <form onSubmit={(e) => {
        e.preventDefault()
        formsubmit()
      }}>
        <div className={`lg:flex sm:flex md:flex xl:flex block gap-5`}>
          <div className={`relative w-full  md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}>
            <TextField fullWidth id="outlined-basic" value={policyuser.username} classes={{ root: classes.root }}

              onChange={(e) => updateuserdata("username", e.target.value.replace(/[^a-zA-Z0-9-./: ]/g, ''))}
              size="small" label="UserName" variant="outlined" />
            <span className="error-form">{error.username}</span>
          </div>

          <div className={`relative w-full  md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}>
            <TextField fullWidth id="outlined-basic" value={policyuser.useremail} classes={{ root: classes.root }} onChange={(e) => updateuserdata("useremail", e.target.value.replace(/[^a-zA-Z0-9-./@]/g, ''))} size="small" label="Email" variant="outlined" />
            <span className="error-form">{error.useremail}</span>
          </div>
        </div>

        <div className={`lg:flex sm:flex md:flex xl:flex block gap-5`}>
          <div className={`relative w-full  md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}>
            <TextField fullWidth id="outlined-basic"
              type="text"
              value={policyuser.usermobile}
              classes={{ root: classes.root }}
              onChange={(e) => updateuserdata("usermobile", e.target.value.replace(/[^0-9]/g, ''))}
              size="small" label="Mobile Number"
              inputProps={{ maxLength: 10, minLength: 10 }}
              variant="outlined" />
            <span className="error-form">
              {error.usermobile}
            </span>
          </div>

          <div className={`relative w-full  md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}>
            <TextField fullWidth
              id="outlined-basic"
              value={policyuser.userpassword}
              type={showPassword ? "text" : "password"}
              classes={{ root: classes.root }}
              onChange={(e) => updateuserdata("userpassword", e.target.value.replace(/\s/g, ''))}
              disabled={showpw}
              size="small" InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                    disabled={showpw}
                      sx={{ background: "" }}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }} label="Password" variant="outlined" />
            <span className="error-form">{error.userpassword}</span>
          </div>
        </div>
        <div className={`lg:flex sm:flex md:flex xl:flex block gap-5`}>
          <div className={`relative w-full  md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={["Active", "InActive"]}
              filterOptions={(options, { inputValue }) => {
                const trimmedInput = inputValue.trim().toLowerCase();
                return options.filter(option => option.toLowerCase().includes(trimmedInput));
              }}
              value={policyuser.status}
              classes={{ root: classes.root }}
              onChange={(event, value) => updateuserdata("status", value)}
              renderInput={(params) => <TextField {...params} label="Status"
                sx={{ borderRadius: "4px" }}
              />}
            />
            <span className="error-form">{error.status}</span>
          </div>
          <div className={`relative w-full  md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={["Admin", "Viewer", "Creator"]}
              filterOptions={(options, { inputValue }) => {
                const trimmedInput = inputValue.trim().toLowerCase();
                return options.filter(option => option.toLowerCase().includes(trimmedInput));
              }}
              value={policyuser.staffRole}
              classes={{ root: classes.root }}
              onChange={(event, value) => updateuserdata("staffRole", value)}
              renderInput={(params) => <TextField {...params} label="Role"
                sx={{ borderRadius: "4px" }}
              />}
            />
            <span className="error-form">{error.staffRole}</span>
          </div>
        </div>
        <Stack direction="row" spacing={2}>
          {
            role == "User" ?
              <Button
                className="module-border-wrap w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10  ml-10  text-black font-bold"
                disabled
                variant="contained"
                endIcon={<SendIcon />}
              >
                Submit
              </Button>
              :
              <Button
                className="module-border-wrap w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn ml-10  text-black font-bold"
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
              >
                Submit
              </Button>
          }
        </Stack>
      </form>
      <div className="createList-table mt-5">
        <table className="scroll_hide  w-full" >
          <thead>
            <tr>
              <th>Staff Id</th>
              <th>Staff User</th>
              <th>Staff Email</th>
              <th>Staff Mobile</th>
              <th>Access Status</th>
              <th>Staff Role</th>
              {
                role == "Viewer" || role == "Creator" ? '' :
                  <th>Actions</th>
              }
            </tr>
          </thead>

          <tbody>
            {tabledata.slice(page * perPage, (page + 1) * perPage).map((staffdata: any) => (
              <tr key={staffdata.id}>
                <td data-label="staff Id">{staffdata.staffId}</td>
                <td data-label="staff User">{staffdata.staffUser}</td>
                <td data-label="staff Email">{staffdata.staffEmail}</td>
                <td data-label="staff Mobile">{staffdata.staffMobile}</td>
                <td data-label="Access Status">{staffdata.accstatus}</td>
                <td data-label="Role">{staffdata.staffRole}</td>

                {role == "Viewer" || role == "Creator" ? '' :
                  <td data-label="Action">
                    <Tooltip title="Edit" >
                      <IconButton
                        // disabled={showpw}
                        // className="defalutlink-btn"
                        // to={`/insurance/Adduser`}
                        onClick={() => {
                          editUsertype(staffdata.staffId);
                          passShow();
                        }}
                      >
                        <FaEdit fontSize="x-large" fill="white" className="flex-shrink-0  text-md font-bold border bg-gradient-to-r
                                 from-blue-400 to-blue-400 hover:to-orange-500 hover:from-orange-600 text-white
                                    transition-all flex items-center justify-center rounded p-1 p-1 mr-1 rounded "/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        // className="default-btn"
                        onClick={() => handleClickOpen(staffdata.staffId)}
                        color="primary"
                        aria-label="upload picture"
                        // component="Link"
                        sx={{ padding: "0px!important" }}
                        className="text-sm text-white flex items-center justify-center p-1 ml-2  p-1 mr-1 "
                      >
                        <DeleteIcon fontSize="medium" fill="white" className="flex-shrink-0  text-sm border bg-gradient-to-r from-red-500 to-red-600 hover:to-orange-500 hover:from-orange-600 text-white
                             transition-all flex items-center justify-center rounded p-1 p-1 mr-1 rounded "/>
                      </IconButton>
                    </Tooltip>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <tfoot>
        <TablePagination
          component="div"
          count={tabledata.length}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          rowsPerPage={perPage}
          rowsPerPageOptions={[]}
          sx={{ marginTop: "5px" }}
          className={"bg-grey"}
        />
      </tfoot>
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { height: 'auto' } }} >
        <DialogTitle>Confirmation Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteuser}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Addpolicytype;
