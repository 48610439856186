import React, { useEffect, useContext } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../services/login.service";
import { EToasterVariants } from "../../services/types/common.type";
import appContext from "../../context/app.context";

const SSOPage: React.FC = () => {
  const { keycloak } = useKeycloak();
  
  alert("SSOPage");
  console.log(keycloak);
  const navigate = useNavigate();
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const getLoginData = async (email: string) => {
    LoginService.ssoLogin({ email })
      .then(function (response) {
        if (response.data.status == 1) {
          sessionStorage.setItem("username", response.data.name || "");
          sessionStorage.setItem("userid", response.data.userId || "");
          sessionStorage.setItem("password", response.data.password || "");
          sessionStorage.setItem("email", response.data.email || "");
          sessionStorage.setItem("mobileNo", response.data.mobileNo || "");
          sessionStorage.setItem("auth", JSON.stringify(true));
          sessionStorage.setItem("staffRole", response.data.staffRole || "");
          showToaster(EToasterVariants.success, "Login Successful!");
          return navigate("/insurance/dashboard"), setSpinnerVisibility(false);
        }
      })
      .catch((err) => {
        // setError(err.response.data);
        setSpinnerVisibility(false);
      });
  };

  useEffect(() => {
    if (keycloak?.authenticated) {
      console.log(keycloak.authenticated, "authenticated");
      const data: any = keycloak?.tokenParsed;
      console.log(data, "authenticated");
      try {
        getLoginData(data.email);
      } catch (error) {
        showToaster(EToasterVariants.error, (error as Error).message);
      }
    }
  }, [keycloak?.authenticated]);

  return <div className="login-page-wrapper"> Loading...</div>;
};

export default SSOPage;
