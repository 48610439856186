import React, { useEffect, useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import AnimationIcon from "@mui/icons-material/Animation";
import PageHeader from "../../components/PageHeader";
import { DashboardService } from "../../services/dashboard.service";
import { FaHome } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import bg from "../../asset/2.jpg";

const useStyles = makeStyles((theme) => ({
  iconpapper: {
    background: bg,
    borderRadius: "0.75rem !important",
    boxShadow:
      "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%) !important",
    overflow: "visible!important",
    border: "1px solid #d1d5db",
  },
}));

const Dashboard: React.FC = () => {
const [staffdata, setstaffdata] = useState([] as any);
const [policydata, setpolicydata] = useState([] as any);
const [govtdata, setgovtdata] = useState([] as any);


  const [dashboardForm, setDashboardForm] = useState<any>([]);
  const [post, setPost] = useState(false);
  const [value, setValue] = React.useState<Date | null>(new Date());
  const classes = useStyles();
  const getDashboard = () => {
    const Dashboard = new DashboardService();
    Dashboard.getDashboardCount()
      .then((res: any) => {
        setDashboardForm(res.data);
        setPost(true);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getDashboard();
    getStaffdata();
    getPolicydata();
    getgovtproofdata();
  }, []);
  
   const getStaffdata = () => {
    axios
      .get(process.env.REACT_APP_API_URL+"insurance/staffdata")
      .then((res) => {
        setstaffdata(res.data.staffcount[0].count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPolicydata = () => {
    axios
      .get(process.env.REACT_APP_API_URL+"insurance/policydata")
      .then((res) => {
        setpolicydata(res.data.policycount[0].count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

const getgovtproofdata = () => {
    axios
      .get(process.env.REACT_APP_API_URL+"insurance/govtproofdata")
      .then((res) => {
        setgovtdata(res.data.policycount[0].count);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <div className="main_wrapper">
      <PageHeader
        title="Dashboard"
        subTitle="Dashboard Offers..."
        icon={<FaHome />}
      />

      <div className="mt-20">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <Box>
              <Card className={classes.iconpapper}>
                <div className="dash_cont_wrp flex justify-between p-5">
                  <div className="dash_icon">
                    <AnimationIcon />
                  </div>
                  <div className="dash_cont">
                    <span>Policy Data</span>
                    <h4 className="text-orange-600">{policydata}</h4>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={3}>
            <Box>
              <Card className={classes.iconpapper}>
                <div className="dash_cont_wrp flex justify-between p-5">
                  <div className="dash_icon cash_off">
                    <LanguageIcon />
                  </div>
                  <div className="dash_cont">
                    <span>Staff Data</span>
                    <h4>{staffdata}</h4>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={3}>
            <Box>
              <Card className={classes.iconpapper}>
                <div className="dash_cont_wrp flex justify-between p-5">
                  <div className="dash_icon cash_off">
                    <LanguageIcon />
                  </div>
                  <div className="dash_cont">
                    <span>Govt Proof Data</span>
                    <h4>{govtdata}</h4>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid> */}
          
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
