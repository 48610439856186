import React, { useContext } from "react";
import { faCircleCheck, faCircleExclamation, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appContext from "../../context/app.context";

import './style.scss';

import { EToasterVariants } from "../../services/types/common.type";

const Toaster: React.FC = () => {

    const { toasterProps } = useContext(appContext);
    
  return (
    <div className={`toaster ${toasterProps.visibility ? 'show' : 'hide'}`}>
      <div className={`border ${toasterProps.type}`} />
      <div className="container">
        {toasterProps.type === EToasterVariants.success && <FontAwesomeIcon className="toaster-icon success" icon={faCircleCheck} />}
        {toasterProps.type === EToasterVariants.warning && <FontAwesomeIcon className="toaster-icon warning" icon={faTriangleExclamation} />}
        {toasterProps.type === EToasterVariants.error && <FontAwesomeIcon className="toaster-icon error" icon={faCircleExclamation} />}
        <p>{toasterProps.message}</p>
      </div>
    </div>
  );
};

export default Toaster;

