import React from "react";
import { EToasterVariants } from "../services/types/common.type";



const appState = {

    sideMenuVisibility: false,
    setSideMenuVisibility: (visibility: boolean) => { },

    spinnerVisibility: false,
    setSpinnerVisibility: (visibility: boolean) => { },
    
    toasterProps: { type: "success", message: "Success Message", visibility: false },
showToaster: (type: EToasterVariants, message: string) => { },


    backDrop:false,
    setbackDrop:(backDrop: any) => { },
    
}

export const appContext = React.createContext(appState);


export default appContext;

