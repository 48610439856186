import React, { useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import { NewOfflineService } from "../../services/newOfflineOrders.service";
import AddIcon from "@mui/icons-material/Add";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { green } from "@mui/material/colors";
type OffersProps = {
  offersType: any;
};

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  backgroundColor: green[500],
  "&:hover": {
    backgroundColor: green[700],
  },
}));

const Offlinetable = (props: OffersProps) => {
  const { offersType = "" } = props || {};
  const [open, setOpen] = React.useState(false);
  const [offlineorders, setOfflineorders] = useState<any>([]);
  const [headerData, setHeaderData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [pages, setPages] = useState<number>(5);
  const [paging, setPaging] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<any>();

  const getOffersList = (pages: any, paging: any, search: any) => {
    const FreeOffersResponse = new NewOfflineService();

    const newObj = {
      pages,
      paging,
      offersType,
      search,
    };

    FreeOffersResponse.getList(newObj)
      .then((res: any) => {
        setOfflineorders(res.data.OffersData);
        setCount(res.data.count);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (search) {
      getOffersList(pages, paging, search);
    } else {
      getOffersList(pages, paging, "");
    }
  }, [search, pages, paging]);

  return (
    <div>
      <div className="mt-5 flex items-center justify-between">
        <div>
          {/* <Controls.Button
             text="Add New"
             startIcon={<AddIcon />}
             /> */}
          <ColorButton variant="contained" startIcon={<AddIcon />}>
            New Offers
          </ColorButton>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <Controls.Input
              label="Search"
              value={search}
              // onChange={handelsearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <FilterAltIcon />
          </div>
          <div>
            <ViewComfyIcon />
          </div>
        </div>
      </div>

      <table className="content-table mt-5">
        <thead>
          <tr>
            <th>OfferID</th>
            <th>OffersName</th>
            <th>OffersType</th>
            <th>Sponsor</th>
            <th>SponsorID</th>
            {/* <th>ExSchemeID</th> */}

            {/* <th>Exclusivity</th> */}
            <th>Channel</th>
            <th>State</th>
            <th>StartDate</th>
            <th>EndDate</th>
          </tr>
        </thead>
        <tbody>
          {offlineorders.map((item: any, index: any) => (
            <tr>
              <td>{item.offerID}</td>
              <td>{item.offersName}</td>
              <td>{item.offersType}</td>
              <td>{item.sponsor}</td>
              <td>{item.sponsorID}</td>
              {/* <td>{item.exSchemeID}</td> */}
              {/* <td>{item.exclusivity}</td> */}
              <td>{item.channel}</td>
              <td>{item.state}</td>
              <td>{item.startDate}</td>
              <td>{item.endDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Offlinetable;
