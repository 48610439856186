import { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import _ from "lodash";
// import Keycloak from 'keycloak-js';
const { REACT_APP_SSO_CLIENT = "" } = process.env;

/**
 * Returns the auth info and some auth strategies.
 */
export const useAuth = () => {
    const { keycloak, initialized } = useKeycloak();
// console.log(keycloak);
    const [user, setUser] = useState({});

    const fetchUserInfo = async () => {
        try {
            console.log("inside");
            const userProfile = await keycloak?.loadUserProfile();
           
            const fullName = `${userProfile?.firstName} ${userProfile?.lastName}`;
            
            setUser({ ...userProfile, fullName });
        } catch (err) {
            alert("You don't have permission");
        }
    };


    useEffect(() => {
        
        if (!initialized) {
            return;
        }
        // console.log("setUsers");
        // console.log(keycloak?.authenticated,"setUsers");
        if (keycloak?.authenticated) {
            fetchUserInfo();
        }
    }, [initialized, keycloak]);

    const login = useCallback(() => {
        keycloak?.login();
    }, [keycloak]);

    const logout = useCallback(() => {
        return new Promise<boolean>((resolve, reject) => {
            keycloak?.logout()
                .then(() => {
                    resolve(true); // Resolve the promise on successful logout
                })
                .catch((error:any) => {
                    reject(error); // Reject the promise on logout failure
                });
        });
    }, [keycloak]);

    const register = useCallback(() => {
        keycloak?.register();
    }, [keycloak]);



    return {
        isAuthenticated: keycloak?.authenticated,
        initialized,
        meta: {
            keycloak,
        },
        token: keycloak?.token,
        user,
        roles: keycloak?.realmAccess,
        accessPermission: _.has(keycloak?.resourceAccess, REACT_APP_SSO_CLIENT),
        login,
        logout,
        register,
    };
};