import React from "react";
//import {Button as MuiButton} from '@mui/material/Button';
import Button from "@mui/material/Button";

import { makeStyles } from "@mui/styles";

type ButtonProps = {
  text?: string;
  size?: any;
  color?: any;
  variant?: any;
  onClick?: () => void;
  startIcon?: any;
  className?: any;
};

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(0.5),
    borderRadius: "0px!important",
  },
  label: {
    textTransform: "none",
    borderRadius: 5,
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const Buttonbtn = ({
  text,
  size,
  color,
  variant,
  onClick,
  startIcon,
  className,
  ...other
}: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      variant={variant || "contained"}
      //size={size || "large"}
      color={color || "primary"}
      onClick={onClick}
      {...other}
      startIcon={startIcon}
      className={className}
    >
      {text}
    </Button>
  );
};

export default Buttonbtn;
