import React, { useState } from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import HomeLayout from './components/Layout/HomeLayout';
import Addpolicy from './pages/insurancepolicy/addpolicy';
import Renewpolicy from './pages/insurancepolicy/renewpolicy';
import appContext from "./context/app.context";
import Spinner from "./components/Spinner";
import Toaster from "./components/Toaster";
import { toasterDuration } from "./common/constant";
import {
EToasterVariants,
ToasterProperties,
} from "./services/types/common.type";
import SSOPage from './pages/login/ssopage';
var timeout: any;


function App() {
  const [sideMenuVisibility, setSideMenuVisibility] = useState<boolean>(true);
  const [spinnerVisibility, setSpinnerVisibility] = useState<boolean>(false);
  const [backDrop, setbackDrop] = useState<boolean>(false);

  const [toasterProps, setToasterProps] = useState<ToasterProperties>({
    type: EToasterVariants.success,
    message: "Success Message",
    visibility: false,
    });
    
    const showToaster = (type: EToasterVariants, message: string) => {
    clearTimeout(timeout);
    setToasterProps({ type, message, visibility: true });
    timeout = setTimeout(() => {
    setToasterProps({ type, message, visibility: false });
    }, toasterDuration);
    };
  const contextItems = {
    sideMenuVisibility,
    setSideMenuVisibility,
    spinnerVisibility,
    setSpinnerVisibility,
    toasterProps,
    showToaster,
    backDrop,
    setbackDrop,
  };
  return (
    <appContext.Provider value={contextItems}>
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/insurance/*" element={<HomeLayout />} />
          <Route path="/PolicyAdd" element={<Addpolicy />} />
          <Route path="PolicyAdd/:pid/edit" element={<Addpolicy />} />
          <Route path="/Renewpolicy" element={<Renewpolicy />} />
          <Route path='/sso-login' element={<SSOPage />} />
        </Routes>
        <Toaster/>
        <Spinner/>

      </Router>
    </div>
    </appContext.Provider>
  );
}

export default App;
