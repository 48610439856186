import React, {
  useState,
  SyntheticEvent,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../services/login.service";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../asset/Poorvika.png";
import logo1 from "../../asset/favicon.png";
import { Button } from "@material-ui/core";
import appContext from "../../context/app.context";
import { Autocomplete, InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MuiOtpInput } from "mui-one-time-password-input";
import bgbanner from "../../asset/login1.gif";
import { EToasterVariants } from "../../services/types/common.type";
import { useAuth } from "../../common/useKeyCloak";
import "./loginformstyle.scss";

const useStyles = makeStyles((theme) => ({
  errmessage: {
    color: "red",
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "left",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid rgba(230, 113, 17, 0.938)", // customized
      },
    },
  },
  root: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)", // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(230, 113, 17, 0.938)", // customized
      },
      MuiFormControlLabel: {
        focused: {
          color: "#000",
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "orange",
        },
      },
    },
  },
}));
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
const LoginFormEx = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const navigate = useNavigate();
  const mode = "login";
  const [state, setState] = useState({});
  const toggleMode = () => {
    var newMode = mode === "login" ? "signup" : "login";
    setState({ mode: newMode });
  };
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const [isSmall, setIsSmall] = useState(false);
  const [modelShow, setmodelShow] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const keycloak: any = useAuth();
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useLayoutEffect(() => {
    setIsSmall(window.innerWidth <= 768);
    window.onresize = (_e: any) => {
      setIsSmall(window.innerWidth <= 768);
    };
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("auth")) {
      navigate("/insurance/dashboard");
    }
  }, []);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [updatepassword, setupdatePassword] = useState("");
  const [mail, setEmail] = useState("");

  const handleOpen = () => {
    setSpinnerVisibility(true);
    if (!!username) {
      var formdata = {
        username: username,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + "insurance/forgot_password",
          formdata
        )
        .then((res) => {
          if (res.data.status == 1) {
            setEmail(res.data.mailid);
            setOpen(true);
            setSpinnerVisibility(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setError({ username: "Username is Incorrect" });
          setSpinnerVisibility(false);
        });
    } else {
      setError({ username: "Username is Required" });
    }
  };

  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
    setOpen(false);
  };

  const handleClose2 = () => {
    if (!updatepassword) {
      setError({ Updatepassword: "Password is Required" });
      return false;
    }

    var formdata = {
      username: username,
      password: updatepassword,
    };
    setSpinnerVisibility(true);
    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/forgot_password_update",
        formdata
      )
      .then((res) => {
        if (res.data.status == 1) {
          sessionStorage.setItem("username", res.data.result[0].staffUser);
          sessionStorage.setItem("userid", res.data.result[0].staffId);
          sessionStorage.setItem("password", res.data.result[0].staffPass);
          sessionStorage.setItem("email", res.data.result[0].staffEmail);
          sessionStorage.setItem("mobileNo", res.data.result[0].staffMobile);
          sessionStorage.setItem("staffRole", res.data.result[0].staffRole);
          sessionStorage.setItem("auth", JSON.stringify(true));
          return navigate("/login"), setSpinnerVisibility(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setOpen2(false);
  };

  const [otp, setOtp] = React.useState("");

  function matchIsNumeric(text: string) {
    const isNumber = typeof text !== "number";

    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value: string, index: any): any => {
    return matchIsNumeric(value);
  };

  const handleChange = (newValue: any): any => {
    setOtp(newValue);
    setError("");
    var formdata = {
      username: username,
      otp: newValue,
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + "insurance/forgot_checkotp",
        formdata
      )
      .then((res) => {
        if (res.data.status == 1) {
          setOpen2(true);
          setOpen(false);
        }
      })
      .catch((err) => {
        if (newValue.length == 6) {
          setError({ otperror: "OTP is incorrect" });
        }
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [error, setError] = useState([] as any);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setSpinnerVisibility(true);
    const formdata = {
      username: username,
      password: password,
    };
    LoginService.login(formdata)
      .then(function (response) {
        if (response.data.status == 1) {
          sessionStorage.setItem("username", response.data.name);
          sessionStorage.setItem("userid", response.data.userId);
          sessionStorage.setItem("password", response.data.password);
          sessionStorage.setItem("email", response.data.email);
          sessionStorage.setItem("mobileNo", response.data.mobileNo);
          sessionStorage.setItem("auth", JSON.stringify(true));
          sessionStorage.setItem("staffRole", response.data.staffRole);
          showToaster(EToasterVariants.success, "Login Successful!");
          return navigate("/insurance/dashboard"), setSpinnerVisibility(false);
        }
      })
      .catch((err) => {
        setError(err.response.data);
        setSpinnerVisibility(false);
      });
  };

  const ssoLogin = async () => {
    const token = keycloak?.token ?? "";
    const loginResponse: any = await LoginService.ssoLogin(token);
    if (loginResponse.data.status == 1) {
      sessionStorage.setItem("username", loginResponse.data.name || "");
      sessionStorage.setItem("userid", loginResponse.data.userId || "");
      sessionStorage.setItem("password", loginResponse.data.password || "");
      sessionStorage.setItem("email", loginResponse.data.email || "");
      sessionStorage.setItem("mobileNo", loginResponse.data.mobileNo || "");
      sessionStorage.setItem("auth", JSON.stringify(true));
      sessionStorage.setItem("staffRole", loginResponse.data.staffRole || "");
      showToaster(EToasterVariants.success, "Login Successful!");
      return navigate("/insurance/dashboard"), setSpinnerVisibility(false);
    }
  };

  useEffect(() => {
    if (keycloak && keycloak?.isAuthenticated) {
      if (keycloak?.accessPermission) {
        ssoLogin();
      } else {
        alert("You don't have a permission");
      }
    }
  }, [keycloak?.isAuthenticated]);
  const classes = useStyles();
  return (
    <div className="mainlogindiv">
      <div className="subbackground">
        <div className="backgroundclr"></div>
        {/* <div className="container_margin2">
        </div> */}
        <div className="imglogin">
          <img className="loginimg" src={bgbanner} alt="bgbanner" />
        </div>
        <div className="middle_align2">
          <div className="">
            <img
              src={logo1}
              alt="logo1"
              sizes="small"
              className="m-auto w-32"
            />
            <h3 className="text-center text-orange-400 font-bold text-xxl mb-7">
              INSURANCE
            </h3>
            <p
              className="text-center  font-bold text-zinc-900 mb-7"
              style={{ fontSize: 30 }}
            >
              SIGN IN
            </p>
            <div className="auth_content">
              <form
                className="bg-white"
                autoComplete="new-password"
                onSubmit={submit}
              >
                <div className="bg-white">
                  <div className=" mt-2 form-group form-group--login">
                    <div className="input-group mb-7">
                      <TextField
                        onChange={(e: any) => {
                          setUsername(e.target.value);
                          setError("");
                        }}
                        fullWidth
                        classes={{
                          root: classes.errmessage,
                        }}
                        size="small"
                        name="username"
                        sx={{
                          boxShadow: "2px 2px 2px rgba(229, 106, 39, 0.349)",
                        }}
                        InputProps={{
                          sx: {
                            "&:hover fieldset": {
                              border:
                                "2px solid  rgba(219, 106, 39, 0.349)!important",
                              borderRadius: "5px",
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <AccountCircleIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        label="Username"
                        className="appearance-none  rounded w-full py-2 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
                      />
                    </div>
                    <div className={classes.errmessage}>
                      {error.username ? error.username : ""}
                    </div>
                    <div className="input-group pwd-wrp mt-3 mb-5">
                      <TextField
                        type={showPassword ? "text" : "password"}
                        size="small"
                        classes={{
                          root: classes.errmessage,
                        }}
                        InputProps={{
                          sx: {
                            "&:hover fieldset": {
                              border:
                                "2px solid  rgba(219, 106, 39, 0.349)!important",
                              borderRadius: "5px",
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{ background: "" }}
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={password}
                        label="Password"
                        onChange={(e: any) => {
                          const trimmedValue = e.target.value.trim();
                          setPassword(trimmedValue);
                          setError("");
                        }}
                        name="password"
                        sx={{
                          boxShadow: "2px 2px 2px rgba(229, 106, 39, 0.349)",
                        }}
                        className="bg-gray-100 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      />
                    </div>
                    <div className={classes.errmessage}>
                      {error.password ? error.password : ""}{" "}
                    </div>
                    <div className="flex cursor-pointer">
                      <Button
                        className="w-48 h-10   py-10 default-btn text-black font-bold"
                        type="submit"
                        variant="contained"
                      >
                        Sign In
                      </Button>

                      <div className="form-group">
                        <div className="w-50 text-md-right text-gray-500 font-bold">
                          <p
                            onClick={handleOpen}
                            className="p-3 underline underline-offset-1 text-blue-400"
                          >
                            Forgot Password ?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <Button
                className="w-48 h-10   py-10 default-btn text-black font-bold"
                type="submit"
                variant="contained"
                onClick={() => keycloak?.login()}
              >
                SSO LOGIN
              </Button>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="password-wrp-main">
            <div className="password-wrp">
              <div className="pass-close">
                <div>
                  <h4>Reset Password</h4>
                </div>
                <div onClick={handleClose} className="cursor-pointer">
                  <CloseIcon />
                </div>
              </div>

              <h5 className="mt-8">
                An OTP has been sent to your{" "}
                <span className="mail-highlight">{mail} </span> please enter the
                number to continue
              </h5>

              <div className="pass-inputs-wrp">
                <MuiOtpInput
                  value={otp}
                  onChange={handleChange}
                  length={6}
                  validateChar={validateChar}
                  TextFieldsProps={{ size: "small", placeholder: "-" }}
                />
                <div className={classes.errmessage}>
                  {error.otperror ? error.otperror : ""}{" "}
                </div>
                {/* <Button
                      className=" h-10 border bg-orange-500 default-btn   text-black font-bold"
                      type="submit"
                      variant="contained"
                      onClick={handleOpen2}
                    >
                      Ok
                </Button> */}
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={style} className="chang-pwd">
            <div
              className="justify-between flex  close-btn cursor-pointer"
              onClick={handleClose2}
            >
              <h2 id="child-modal-title">Change Password</h2>
              <CloseIcon />
            </div>

            <form>
              <TextField
                type={showConfirmPassword ? "null" : "password"}
                size="small"
                value={updatepassword}
                placeholder="Password"
                onChange={(e: any) => {
                  const trimUpdatePass = e.target.value.trim();
                  setupdatePassword(trimUpdatePass);
                }}
                fullWidth
                className=" appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ background: "" }}
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.errmessage}>
                {error.Updatepassword ? error.Updatepassword : ""}{" "}
              </div>
              <div className="mt-7"></div>
              <Button className="default-btn-set" onClick={handleClose2}>
                Set Password
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default LoginFormEx;
