import { BaseService } from './htttp'
import { API_ENDPOINTS,parseAxiosError} from '../config/config'
import { IErrorResponse, ISuccessResponse,OfflineResponse } from './interfaces/common.interface'
import { AxiosError } from "axios";
import { EncodeGetParams } from '../common/constant';


// export const encodeGetParams = (params: any) => {
//   return Object.entries(params).map((key: any) => key.map((k: any) => {
//       k = typeof k === 'object' ? JSON.stringify(k) : k;
//       return encodeURIComponent(k);
//   }).join("=")).join("&");
// };





export class NewOfflineService extends BaseService {
 
  public async getList(data:any) : Promise<ISuccessResponse | IErrorResponse>{
   const {pages="", paging="", offersType="", search=""} = data;
   const newobj = {
    pages:pages,
    paging:paging,
    types:offersType,
    search:search
   };
   const queryString = EncodeGetParams(newobj);
    try {
        const { data } = await this.httpClient.get(API_ENDPOINTS.NEW_OFFLINE_ORDERS_LIST+ `?${queryString}`,{
            headers: {
              Authorization: `${sessionStorage.getItem('accessToken')}` 
            },
            
          }        
            );
           
      return data
      
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  
  public async InsertOfflineOrders(OfflineData:any) : Promise<ISuccessResponse | IErrorResponse>{
    try {
      
        const { data } = await this.httpClient.post(API_ENDPOINTS.OFFLINEINSERT, OfflineData, {
          headers: { 'authorization': `${sessionStorage.getItem('accessToken')}` } 
          })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async UpdateOfflineOrders( OfflineData:any) : Promise<ISuccessResponse | IErrorResponse>{
    try {
      const { data } = await this.httpClient.put(API_ENDPOINTS.OFFLINEUPDATE,OfflineData, {
        headers: 
        { 'authorization': `${sessionStorage.getItem('accessToken')}` }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public  async DeleteOfflineOrders(_id:any) : Promise<ISuccessResponse | IErrorResponse>{
    try {

      const { data } = await this.httpClient.delete(API_ENDPOINTS.OFFLINEDELETE,{
        headers: {
          Authorization: `${sessionStorage.getItem('accessToken')}` 
        },
        data: {
          _id: _id
        }
      }        
        );

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public  async MultiDelete(_id:any) : Promise<ISuccessResponse | IErrorResponse>{
    try {

      const { data } = await this.httpClient.delete(API_ENDPOINTS.OFFLINE_MULTIDELETE,{
        headers: {
          Authorization: `${sessionStorage.getItem('accessToken')}` 
        },
        data: {
          _id: _id
        }
      }        
        );

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }



}
