import React, { useEffect, useState, useContext } from 'react';
import appContext from "../../context/app.context";
import { FaEdit } from "react-icons/fa";
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { makeStyles } from "@mui/styles";
import { EToasterVariants } from "../../services/types/common.type";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        color: "orange",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)", // default
            },
            "&.Mui-focused fieldset": {
                border: "1px solid rgba(230, 113, 17, 0.938)", // customized
            },
            MuiFormControlLabel: {
                focused: {
                    color: "#000",
                },
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: "orange",
                },
            },
        },
    },

}));

const InsuranceCompanyName = () => {
    const classes = useStyles();
    const [tabledata, settabledata] = useState([] as any);
    const policyfields = {
        company_name: "",
        company_id: ""
    };
    const [policyTypeField, setPolicyTypeField] = useState(policyfields) as any;
    const [error, setError] = useState([] as any);
    const { showToaster, setSpinnerVisibility } = useContext(appContext);
    const updateInsuranceCompanyName = (key: any, val: any) => {
        setError('')
        setPolicyTypeField({
            ...policyTypeField,
            [key]: val
        })
    }

    const getInsuranceCompanyName = () => {
        setSpinnerVisibility(true);
        axios
            .get(
                process.env.REACT_APP_API_URL + "insurance/ins_companyname_list"
            )
            .then((res) => {
                if (res.data.status == 1) {
                    settabledata(res.data.ins_companylist);
                    setSpinnerVisibility(false);
                }
                else {
                    setSpinnerVisibility(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const editInsuraceCompanyName = (company_id: any) => {
        axios
            .get(
                process.env.REACT_APP_API_URL + "insurance/editins_companyname/" + company_id
            )
            .then((res) => {
                if (res.data.status == 1) {
                    setPolicyTypeField({
                        ...policyTypeField,
                        "company_name": res.data.results[0].company_name,
                        "company_id": res.data.results[0].company_id
                    })
                    // setPolicyTypeField(res.data.results[0]);
                }
            }).catch((err) => {
                console.log(err);
            });
    };

    const deleteInsuranceCompanyName = () => {
        if (selectedId) {
            axios
                .get(
                    process.env.REACT_APP_API_URL + "insurance/deleteins_companyname/" + selectedId
                )
                .then((res) => {
                    if (res.data.status == 1) {
                        setShow(false)
                        getInsuranceCompanyName()
                        showToaster(EToasterVariants.success, "Insurance Company Name Deleted Successfully!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const formsubmit = () => {
        const formdata = {
            ...policyTypeField
        }
        if (!!formdata.company_id) {
            axios.post(process.env.REACT_APP_API_URL + 'insurance/updateins_companyname', formdata).then(res => {
                if (res.data.status == 1) {
                    window.location.reload();

                } else {
                    setError(res.data.errors)
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            axios.post(process.env.REACT_APP_API_URL + 'insurance/insurance_adddcompanyname', formdata).then(res => {
                if (res.data.status == 1) {
                    window.location.reload();
                } else {
                    setError(res.data.errors)
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }


    let role = sessionStorage.getItem('staffRole');
    const [show, setShow] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleClickOpen = (id: any) => {
        setSelectedId(id);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        getInsuranceCompanyName();
    }, []);

    return (
        <div className="main_wrapper">
            <PageHeader title="Insurance Company Name" subTitle="Insurance Company Name..." />
            <form onSubmit={(e) => {
                e.preventDefault()
                formsubmit()
            }}>
                <div className={`lg:flex sm:flex md:flex xl:flex block`}>
                    <div className={`relative w-full mx-0 md:w-2/5 sm:w-2/5 lg:w-2/5 xl:w-2/5 mb-6`}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            value={policyTypeField.company_name}
                            onChange={(e) => updateInsuranceCompanyName("company_name", e.target.value)}
                            classes={{ root: classes.root }}
                            size="small"
                            label="Insurance Company Name"
                            variant="outlined"
                        />
                        <span className="error-form">{error.company_name}</span>
                        <span className="error-form">{error.companyname}</span>
                    </div>
                    <div className={`relative w-full mx-0 md:mx-4 sm:mx-4 lg:mx-4 xl:mx-4 md:w-2/5 sm:w-2/5	lg:w-2/5 xl:w-2/5	mb-6`}>
                    </div>
                </div>
                <Stack direction="row" spacing={2}>
                    <Button
                        className="module-border-wrap w-24 md:w-48 lg:w-48 h-10 border bg-gray-500 py-10 default-btn ml-10  text-black font-bold"
                        type="submit"
                        variant="contained"
                        endIcon={<SendIcon />}
                    >
                        Submit
                    </Button>
                </Stack>
            </form>
            <div className="createList-table mt-5">
                {tabledata.length > 0 ?
                    <table className="scroll_hide  w-full" >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Insurance Company Name</th>
                                {
                                    role == "Viewer" || role == "Creator" ? "" : <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {tabledata.map((companydata: any, index: any) => (
                                <tr>
                                    <td data-label="S.No">{index + 1}</td>
                                    <td data-label="Insurance Company Name">{companydata.company_name}</td>
                                    {
                                        role == "Viewer" || role == "Creator" ? '' :
                                            <td data-label="Actions">
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        //   className="default-btn"
                                                        onClick={() => editInsuraceCompanyName(companydata.company_id)}
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        //   component="Link"
                                                        sx={{ padding: "0px!important" }}
                                                        className="text-sm text-white flex items-center justify-center p-1 ml-2  p-1 mr-1 ">
                                                        <FaEdit fontSize="x-large" fill="white" className="flex-shrink-0  text-md font-bold border bg-gradient-to-r from-blue-400 to-blue-400 hover:to-orange-500
                                                     hover:from-orange-600 text-white
                                                      transition-all flex items-center justify-center rounded p-1 p-1 mr-1 rounded "/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        // className="default-btn"
                                                        onClick={() => handleClickOpen(companydata.company_id)}
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        // component="Link"
                                                        sx={{ padding: "0px!important" }}
                                                        className="text-sm text-white flex items-center justify-center p-1 ml-2  p-1 mr-1 "
                                                    >
                                                        <DeleteIcon fontSize="medium" fill="white" className="flex-shrink-0  text-sm border bg-gradient-to-r from-red-500 to-red-600 hover:to-orange-500
                                                     hover:from-orange-600 text-white transition-all flex items-center justify-center rounded p-1 p-1 mr-1 rounded "/>
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    :
                    <div className="norecordfound">
                        <p>No Record Found!</p>
                    </div>
                }
            </div>
            <Dialog open={show} onClose={handleClose} PaperProps={{ style: { height: 'auto' } }} >
                <DialogTitle>Confirmation Alert</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to delete this record?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteInsuranceCompanyName}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InsuranceCompanyName;
