import React,{useState} from 'react'
import LoginForm from './LoginForm';
import LoginFormEx from './LoginFormEx';

const LoginPage = (props: any) => {

    const [isBusinessLogin, setBusinessLogin] = useState(false);

    const loginTypeHandler = (loginType: boolean) => {
        setBusinessLogin(loginType);
      }


    return (
        <div onClick={() => loginTypeHandler(false)} >
            {/* <LoginForm/> */}
            <LoginFormEx />
        </div>
    )
}

export default LoginPage
